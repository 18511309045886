
// 泛列表头部&滑动悬浮功能区优化 wiki:1493547013
// isNewStyle为各列表页面是否命中平铺&悬浮搜索框实验
export const isFirmsearch = (route, abtInfo, type, isNewStyle) => {
  const mark = type === 'suspension' ? '1' : '2'
  const { query = {}, meta = {}, name } = route
  const { search_type: searchType, srctype, entranceType, nav_entrance_type, nav_src_type } = query || {}
  const { pageType, type: listType } = meta || {}
  const { flowfirmsearch, pfirmsearch, h1firmsearch, sbcfirmsearch, catefirmsearch, newsearch } = abtInfo || {}

  // 没命中之前的强化实验则返回false
  if(pageType === 'productList' && (listType === 'search' || listType === 'entity')) {
    if (newsearch?.p?.Listnewsearch !== 'new') {
      return false
    }
  } else if (!isNewStyle) {
    return false
  }

  // 全局搜索场景没有searchType， 排除店铺、专题、趋势搜索等场景， 这些场景有searchType
  if (searchType) {
    return false
  }

  // 信息流落地页
  if (name === 'picked-info-list' && flowfirmsearch?.p?.[`flowfirmsearch${mark}`] === 'new') {
    return true
  }

  // 全局搜索结果页
  if (pageType === 'productList' && listType === 'search' && pfirmsearch?.p?.[`pfirmsearch${mark}`] === 'new') {
    return true
  }
  
  // h1落地页
  if (pageType === 'productList' && (entranceType === 'h1' || nav_entrance_type === 'h1') && h1firmsearch?.p?.[`h1firmsearch${mark}`] === 'new') {
    return true
  }

  // sbc入口跳转到列表落地页
  if (pageType === 'productList' && (entranceType === 'sbc' || nav_entrance_type === 'sbc')) {
    if (sbcfirmsearch?.p?.[`sbcfirmsearch${mark}`] === 'allnew') {
      return true
    }
    // 仅选品列表页
    if (listType === 'selection' && sbcfirmsearch?.p?.[`sbcfirmsearch${mark}`] === 'scnew') {
      return true
    }
    // 仅真实列表页
    if (listType === 'entity' && sbcfirmsearch?.p?.[`sbcfirmsearch${mark}`] === 'cnew') {
      return true
    }
    return false
  }

  // category入口跳转到列表落地页
  if (pageType === 'productList' && (srctype === 'category' || nav_src_type === 'category')) {
    if (catefirmsearch?.p?.[`catefirmsearch${mark}`] === 'allnew') {
      return true
    }
    // 仅选品列表页
    if (listType === 'selection' && catefirmsearch?.p?.[`catefirmsearch${mark}`] === 'scnew') {
      return true
    }
    // 仅真实列表页
    if (listType === 'entity' && catefirmsearch?.p?.[`catefirmsearch${mark}`] === 'cnew') {
      return true
    }
    return false
  }
  return false
}
