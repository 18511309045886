<template>
  <swiper-container
    ref="swiperRef" 
    class="store-words-swiper__container"
    init="false"
    destroy-on-disconnected="false"
  >
    <swiper-slide
      v-for="(item, index) in storeDefaultWords"
      :key="index"
      class="store-words-swiper__item"
    >
      <span>
        <i
          v-if="index < 2"
          class="
            suiiconfont 
            sui_icon_nav_hot_14px 
            store-words-swiper__item-icon" 
        ></i>
        <span>{{ item.word }}</span>
      </span>
    </swiper-slide>
  </swiper-container>
</template>

<script>
import { getStoreCodeFromUrl } from 'public/src/pages/store_pages/js/utils.js'
import { register } from 'swiper/element'
import { Autoplay } from 'swiper/modules'

typeof window !== 'undefined' && register()

export default {
  name: 'StoreWordsSwiper',
  props: {
    storeDefaultWords: {
      type: Array,
      default: () => [],
    },
    showNewStyle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeIndex: 0,
    }
  },
  mounted() {
    this.initSwiper()
  },
  beforeDestroy() {
    this.swiperEl.swiper.destroy(true, true)
  },
  methods: {
    // 初始化轮播
    initSwiper() {
      let index = 0
      const store_code = getStoreCodeFromUrl()
      let storeSearchHotWordIndex = sessionStorage.getItem(`STORE_SEARCH_${store_code}`)
      if(storeSearchHotWordIndex) {
        // 存在缓存,取缓存索引的下一个
        index = Number(storeSearchHotWordIndex) + 1
        if(index >= this.storeDefaultWords.length) {
          index = 0
        }
      }
      const swiperEl = this.$refs?.swiperRef
      if (!swiperEl) return
      const options = {
        direction: 'vertical',
        loop: true,
        initialSlide: index,
        modules: [Autoplay],
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        on: {
          slideChangeTransitionEnd: (event) => {
            this.activeIndex = event?.realIndex
          }
        }

      }
      Object.assign(swiperEl, options)
      swiperEl.initialize()
      this.swiperEl = swiperEl
    },
  }
}
</script>
<style lang="less" scoped>
.store-words-swiper__container{
  overflow: hidden;
  font-size: 0.32rem;
  height: 100%;
  margin: 0;
}
.store-words-swiper__item{
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.store-words-swiper__item-icon{
  color: rgb(248, 98, 98);
  margin-right: 0.1rem;
}

</style>


