<template>
  <div
    class="legacy-header"
    :style="legacyHeaderStyle"
    :data-key="initSearchBoxLogic"
  >
    <template v-if="isSearchBoxNeedExpandWidth()">
      <Transition 
        :name="isInputAnimationEnabled() ? 'expand': ''" 
        @leave="onLeave"
        @after-leave="onAfterLeave"
        @enter="onEnter"
      >
        <CommonHeaderInput
          v-show="isShowInput"
          :key="initSearchBoxLogic"
          :isShowInput="isShowInput"
          :goodsId="goodsId"
          :commonHeaderBg="commonHeaderBg"
          :searchInputBgColor="searchInputBgColor"
          :cateIds="cateIds"
          :selectId="selectId"
          :bizScene="bizScene"
          :pageTitleName="pageTitleName"
          :isCatNameSearchWrods="isCatNameSearchWrods"
          :page-type="searchEntranceBtnType"
          @resolveSearchTerms="onResolveSearchTerms"
          @initMiddlePage="goPresearch({ camera: 1 })"
          @clickSearchBar="goPresearch"
          @uploadSuccessHandler="uploadSuccessHandler"
          @dealwithBffDefaultWordsResult="dealwithBffDefaultWordsResult"
          @cacheListDefaultWordsReq="cacheListDefaultWordsReq"
        />
      </transition>
    </template>
    <template v-else>
      <CommonHeaderInput
        v-show="isShowInput"
        :key="initSearchBoxLogic"
        :style="headerInputStyle"
        :isShowInput="isShowInput"
        :goodsId="goodsId"
        :commonHeaderBg="commonHeaderBg"
        :searchInputBgColor="searchInputBgColor"
        :cateIds="cateIds"
        :selectId="selectId"
        :bizScene="bizScene"
        :pageTitleName="pageTitleName"
        :isCatNameSearchWrods="isCatNameSearchWrods"
        :page-type="searchEntranceBtnType"
        @resolveSearchTerms="onResolveSearchTerms"
        @initMiddlePage="goPresearch({ camera: 1 })"
        @clickSearchBar="goPresearch"
        @uploadSuccessHandler="uploadSuccessHandler"
        @dealwithBffDefaultWordsResult="dealwithBffDefaultWordsResult"
        @cacheListDefaultWordsReq="cacheListDefaultWordsReq"
      />
    </template>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { storeModuleName } from 'public/src/pages/product_app/store/modules/store_pages'
import {
  throttle,
  transformImg,
  getQueryString,
  debounce
} from '@shein/common-function'
import { getStoreCodeFromUrl } from 'public/src/pages/store_pages/js/utils.js'
import { uploadImgBffApi } from 'public/src/services/api/upload.js'
import { SEARCH_SCROLL_DISTANCE } from './utils/const.js'
import CommonHeaderInput from './search_input/scene/commonHeaderInput.vue'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { markPoint } from 'public/src/services/mark/index.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { isFirmsearch } from '../../utils/utils.js'

import ControlAsync from './utils/controlAsync.js'
const headerAnalysis = new ControlAsync({
  file: () =>
    import(/* webpackChunkName: "header-analysis" */ './analysis/index.js')
}).run()
const searchAnalysis = new ControlAsync({
  file: () =>
    import(
      /* webpackChunkName: "header-search-analysis" */ 'public/src/pages/common/search_words/analysis/index.js'
    )
}).run()

const {
  langPath,
  GB_cssRight,
} = gbCommonInfo
// 处理埋点重复曝光问题
let exposeSearchTextArr = []
// 当前页面黑名单请求缓存
const searchWordBlackReqCacheMap = new Map()
export default {
  name: 'LegacyHeader',
  components: {
    CommonHeaderInput,
  },
  inject: ['isSearchBoxNeedExpandWidth', 'isInputAnimationEnabled'],
  emits: ['inputWidthExpand', 'inputWidthShrink'],
  props: {
    showSearchStoreNewStyle: {
      type: Boolean,
      default: false
    },
    isStoreSearchPage: {
      type: Boolean,
      default: false
    },
    isStorePageOrSearchPage: {
      type: Boolean,
      default: false
    },
    isTopTrendResultPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchEntranceBtnType: 'all', // 搜索分为全站搜索以及店铺搜索
      langPath,
      GB_cssRight,
      headerPresearchScroll: false,
      showSearchCategory: false,
      currPageExposed: false,
      searchFixed: false, // 搜索结果页固定搜索框
      currentKeyWord: '', // 搜索词
      catInfo: {},
      currentWord: {},
      routeName: '',
      storeRightCornerShowMore: false,
      goodsId: '', // goodsDetailInfo?.goodsId 返回不会变，所以从route拿
      abtSBCListshowsearch: false, // SBC列表滑动时候，是否展示悬浮搜索按钮
      abtCateListshowsearch: false, // Category列表滑动时候，是否展示悬浮搜索按钮
      catName: '',
      abtListshowsearch: false, // 真实和选品场景是否展示悬浮搜索按钮实验
      abtRealListshowsearch: false, // 真实场景是否展示悬浮搜索按钮实验
      abtSelectLsitshowsearch: false, // 选品场景是否展示悬浮搜索按钮实验
      abtPicksListshowsearch: false, // picks是否展示悬浮搜索按钮实验
      abtListTitleTypeVal: '', // 真实和选品场景是否展示搜索框实验配置
      abtRealListTitleTypeVal: '', // 真实场景是否展示搜索框实验配置
      abtSelectListTitleTypeVal: '', // 选品场景是否展示搜索框实验配置
      abtPicksListTitleTypeVal: '', // picks场景是否展示搜索框实验配置
      cateIds: '', // 用于存储列表场景的类目id
      selectId: '', // 用于存储列表场景的选品id
      bizScene: '', // 给底纹词接口的场景字段
      pageTitleName: '', // 给底纹词bff接口的标题名称
      isBackFromDetail: false, // 是否是从商详返回的
      abtListShadingWord: false, // 真实/选品/picks等场景底纹词策略
      abtRecListFlowShowSearch: {}, // 信息流列表是否展示搜索框
      showDefaultWordsState: false, // 搜索框是否展示底纹词
      isCatNameSearchWrods: false, // 是否类目名称/页面名称作为搜索词
      cacheListDefaultWordsKey: '', // 用于缓存最新的底纹词请求结果的key
      listDefaultWordsProp: null, // 请求的列表类目底纹词存在缓存的时候，传给input使用，减少重复请求
      abtNewStoreiconchange: false, // 店铺头部icon实验
      abtFollowBtnEntry: false, // 店铺关注店铺入口强化样式实验
      isSearchSticky: false, // 搜索框是否吸顶
      searchSrollHeight: 0, // 搜索框滚动的距离
      firmSearchAbt: {}, // 列表头部滑动悬浮功能区优化实验
      isSmallStatus: false, // 图文导航是否是小图模式
      wordTraceId: '-',
    }
  },
  computed: {
    ...mapState([
      'indexUnReadInfo',
      'commonHeaderBg',
      'searchInputBgColor',
      'goodsDetailInfo',
      'messageViewMode',
      'unReadChatNum',
      'searchSelectKeyWord'
    ]),
    ...mapState(storeModuleName, ['Language', 'Results']),
    legacyHeaderStyle() {
      if (this.showListSearchSuspension || this.showListSearchLayFlat) return {}
      let  translateNumber = 0
      if (this.showSearchStoreNewStyle) {
        translateNumber = 0.96
      } else if (this.isStorePageOrSearchPage) {
        translateNumber = 0.56
      }
      if (!translateNumber) return {}
      return {
        transform: GB_cssRight ? `translateX(-${translateNumber}rem)` : `translateX(${translateNumber}rem)`,
      }
    },
    headerInputStyle() {
      let width = 0
      if (this.isSearchSticky) {
        if (this.showListSearchLayFlat) {
          width = Math.min(7.49, 6.53 + (this.searchSrollHeight / SEARCH_SCROLL_DISTANCE) * 0.96)
        } else {
          width = Math.min(7.49, 5.57 + (this.searchSrollHeight / SEARCH_SCROLL_DISTANCE) * 1.92)
        }
      } else if (this.showSearchStoreNewStyle) {
        width = 7.44
      } else if (this.isStorePageOrSearchPage) {
        width = 6.78
      } else if (this.showListSearchLayFlat) {
        width = 6.53
      } else if (this.isTopTrendResultPage) {
        width = 7.68
      } else {
        width = 5.57
      }
      if (!width) return {}
      return {
        width: `${width}rem`,
        willChange: 'width',
        transition: 'all 0.2s ease-in-out'
      }
    },
    // 列表搜索框平铺 （隐藏购物车按钮）
    showListSearchLayFlat () {
      const showListSearchLayFlat = isFirmsearch(this.$route, this.firmSearchAbt, 'layFlat', this.isFiexdRuleShowInput)
      this.$emit('listSearchLayFlat', showListSearchLayFlat)
      return showListSearchLayFlat
    },
    // 列表搜索框滑动悬浮 （搜索框未置顶时隐藏右侧icon图标）
    showListSearchSuspension () {
      const showListSearchSuspension =  isFirmsearch(this.$route, this.firmSearchAbt, 'suspension', this.isSearchFixed)
      this.$emit('listSearchSuspension', showListSearchSuspension)
      return showListSearchSuspension
    },
    storeResults() {
      return this.Results || {}
    },
    // 店铺相关多语言
    storeLanguage() {
      return this.Language || {}
    },
    isConfigIndexPage() {
      return this.routeName === 'config_index'
    },
    isGoodsDetailPage() {
      return this.routeName === 'page_goods_detail'
    },
    isPickedInfoListPage() {
      return this.routeName === 'picked-info-list'
    },
    isNewCampaignPage() {
      return (
        this.routeName === 'config_activity' &&
        this.$route?.query?.from_tabbar_new === '1'
      )
    },
    needExposedSearch() {
      return this.isShowInput && !this.currPageExposed
    },
    // 从店铺活动组件进入的选品页屏蔽搜索入口
    hideSearchEntry() {
      return (
        ['shop-activity', 'store-coupon'].includes(this.$route?.query?.comp) &&
        (this.$route?.meta?.type === 'selection' ||
          this.$route?.query?.src_module === 'storeHomePage')
      )
    },
    isBrandStore() {
      return !!this.$store.getters['store_pages/isBrandStore']
    },
    // 是否显示搜索框
    isShowInput() {
      // 触发搜索框吸顶时展示
      if (this.searchFixed) return true
      return this.isFiexdRuleShowInput
    },
    // 是否因为固定的规则固定展示搜索框
    isFiexdRuleShowInput() {
      // 从店铺活动组件进入的选品页屏蔽搜索入口
      if (this.hideSearchEntry) return false

      // 分类页面并且实验开启
      if (this.routeName === 'page_category' && this.showSearchCategory)
        return true

      // 店铺页展示
      if (this.checkIsPageStore(this.$route.name)) return true

      if (this.isPickedInfoListPage && this.abtRecListFlowShowSearch?.flowtitletype === 'show') {
        // 信息流落地页平铺搜索框
        return true
      }
      
      if (this.headerPresearchScroll) {
        // 首页实验开启和促销页面滚动
        if (this.isConfigIndexPage) return true // 在首页
        if (this.isNewCampaignPage) return true // 新的专题场景
        if (this.isGoodsDetailPage) return true // 商详实验开启
      }

      const type = this.$route?.meta?.type
      const { fromPageType, entranceType } = this.$route?.query || {}

      // 真实列表
      if (type === 'entity') {
        return this.isShowSearchHead({
          listType: type,
          oldAbtValue: this.abtListTitleTypeVal,
          newAbtValue: this.abtRealListTitleTypeVal,
          fromPageType,
          entranceType
        })
      }

      // 选品列表
      if (type === 'selection') {
        return this.isShowSearchHead({
          listType: type,
          oldAbtValue: this.abtListTitleTypeVal,
          newAbtValue: this.abtSelectListTitleTypeVal,
          fromPageType,
          entranceType
        })
      }

      // pick场景
      if (type === 'picks' && this.abtPicksListTitleTypeVal === 'search')
        return true
      
      return false
    },
    isSearchFixed() {
      const type = this.$route?.meta?.type
      // 真实列表
      if (
        type === 'entity' &&
        (this.abtListshowsearch || this.abtRealListshowsearch)
      )
        return true
      // 选品列表
      if (
        type === 'selection' &&
        (this.abtListshowsearch || this.abtSelectLsitshowsearch)
      )
        return true
      // picks场景
      if (type === 'picks' && this.abtPicksListshowsearch) return true
      // 信息流落地页
      if (this.isPickedInfoListPage && this.abtRecListFlowShowSearch?.flowshowsearch === 'show') return true
      return false
    },
    // 由于 commonHeaderInput 组件使用 v-show 导致 轮播组件/列表搜索框兜底逻辑 需要自行更新组件状态
    // 使用 key 的方式 可以让整个组件在不同条件变化时走一遍完整的搜索框兜底逻辑
    initSearchBoxLogic() {
      const pageType = this.$route?.meta?.pageType

      let componentKey = []
      if (this.selectId) {
        componentKey.push(this.selectId)
      } else if (this.cateIds) {
        componentKey.push(this.cateIds)
      }
      if (this.commonHeaderBg.searchInputBgColor) {
        componentKey.push(this.commonHeaderBg.searchInputBgColor)
      }
      // 针对进入列表的场景，每次都应该初始化
      if (pageType === 'productList') {
        componentKey.push(`uuid-${new Date().getTime()}`)
      }
      return componentKey.join('-')
    }
  },
  watch: {
    $route: function (newVal, oldVal) {
      let pageType = newVal.meta?.pageType
      this.routeName = this.$route.name
      // 路由路径变化清空searchWordBlackReqCacheMap和重置showDefaultWordsState的状态和cacheProductListSearchWordsReq
      if (newVal.path !== oldVal.path) {
        this.isCatNameSearchWrods = false
        this.listDefaultWordsProp = null
        for (let key of searchWordBlackReqCacheMap.keys()) {
          searchWordBlackReqCacheMap.delete(key)
        }
        const listCatName =
          this.$route?.meta?.silInfo?.list_cat_name || this.catName
        this.catName = listCatName
        // 非预搜页和搜索结果页的情况下
        if (!pageType === 'preSearch' || (newVal.meta?.pageType === 'productList' && newVal.meta?.type === 'search') ) {
          this.catInfo = {}
          this.catName = ''
          this.currentKeyWord = ''
          this.assignRootState({
            searchSelectKeyWord: '',
            currentDefaultKeyword: '',
          })
          this.searchFixed = false
        }
        if (oldVal.meta.name === 'pre_search') {
          // 从预搜页返回回来，用框里的词（searchSelectKeyWord）更新预搜页默认词（mixedKeyword）
          this.assignRootState({
            mixedKeyword: this.searchSelectKeyWord,
          })
          gbCommonInfo.keywords = this.searchSelectKeyWord
        }
      }
      // 如果去到首页，清除底纹词缓存
      if (this.routeName === 'config_index') {
        window.sessionStorage.removeItem('cacheProductListSearchWordsReq')
        // 清除分布式的合并词，避免从首页搜索icon到预搜又出现列表的词
        gbCommonInfo.keywords = ''
        this.assignRootState({
          mixedKeyword: ''
        })
      }

      if (this.isPickedInfoListPage) {
        window.sessionStorage.removeItem('pickInfoListDefaultWords')
      }

      if (this.isGoodsDetailPage) {
        // 清除分布式的合并词，避免从商详搜索icon到预搜又出现列表的词
        this.assignRootState({
          mixedKeyword: ''
        })
        gbCommonInfo.keywords = ''
      }

      if (['page_category', 'campaigns'].includes(this.routeName)) {
        // 清除分布式的合并词，避免从 category / campaigns 的搜索icon到预搜又出现列表的词
        this.assignRootState({
          mixedKeyword: ''
        })
        gbCommonInfo.keywords = ''
      }

      if (this.isGoodsDetailPage) {
        this.goodsId = newVal.params['1'] || ''
      }
      this.pageViewed = false
      this.currPageExposed = false
      this.headerPresearchScroll = false
      this.showSearchFixed()
      // dealingSearchInput会触发底纹词请求
      if (newVal.name !== 'pre_search' && !this.currentKeyWord) {
        this.dealingSearchInput()
      }

      if (this.showListSearchSuspension) {
        this.isSmallStatus = null
      }

      // 路由跳转重新更新
      this.setPageType(newVal.name)
      this.showProductListSearchFixed(0)
    },
    needExposedSearch: {
      immediate: true,
      handler(val) {
        if (val) {
          this.currPageExposed = true
          if (this.pageViewed) {
            // this.exposedVisualSearch()
          } else {
            this.waitPageViewedToReport = true
          }
        }
      }
    },
    isShowInput: {
      handler() {
        this.dealingSearchInput({ transfer: 'watchIsShowInput' })
        this.$emit('isShowInput', this.isShowInput)
      },
      immediate: true
    },
    searchSrollHeight: {
      handler() {
        this.$emit('searchStickyInfoChange', {
          isSearchSticky: this.isSearchSticky,
          searchSrollHeight: this.searchSrollHeight
        })
      },
      immediate: true
    },
    isSearchSticky: {
      handler() {
        this.$emit('searchStickyInfoChange', {
          isSearchSticky: this.isSearchSticky,
          searchSrollHeight: this.searchSrollHeight
        })
      },
      immediate: true
    },
    catInfo: {
      handler(newVal, oldVal) {
        if (this.isPickedInfoListPage) {
          // 品类id无变化时不触发请求
          if (newVal?.cat_id === oldVal?.cat_id && newVal?.child_cat_id === oldVal?.child_cat_id && !!newVal.isBackFromDetail === !!oldVal.isBackFromDetail) {
            return
          }
          this.triggerPickedInfoListDefaultWordsReq()
        }
        if (!newVal?.type) return
        newVal.cat_name && (this.catName = newVal.cat_name)
        if (newVal.cat_name && this.isShowInput && !this.isPickedInfoListPage) {
          this.handleProductListSearchWords({
            searchword: newVal.cat_name,
            transfer: 'cateInfo change'
          })
        }
      },
      deep: true
    }
  },
  created() {
    this.routeName = this.$route.name
    this.goodsId = this.goodsDetailInfo?.goodsId || ''

    window.sessionStorage.removeItem('cacheProductListSearchWordsReq')
    window.sessionStorage.removeItem('pickInfoListDefaultWords')
    window.sessionStorage.removeItem('advertisingDefaultWords')
  },
  async mounted() {
    this.getFirmSearchAbt()
    this.initScrollFixed()
    this.bindEvent()
    await this.handleWishlistShow()
    this.dealingSearchInput()
    // 方便其他页面放出事件给头部切换logo  输入框 比如整个页面是overflow滚动
    appEventCenter.$on(
      'changeHeaderPresearchScroll',
      this.setHeaderPresearchScroll
    )
    appEventCenter.$on('resetCatInfo', this.setCatInfo)

    appEventCenter.$on('pdsearchExposure', this.pdsearchExposure)
    appEventCenter.$on('picTopNavStatusChange', this.picTopNavStatusChange)
    appEventCenter.$emit('headerMounted', this) // 这个不能删除
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.preSearchScroll)
    appEventCenter.$off(
      'changeHeaderPresearchScroll',
      this.setHeaderPresearchScroll
    )
    appEventCenter.$off('resetCatInfo', this.setCatInfo)
    appEventCenter.$off('pdsearchExposure', this.pdsearchExposure)
    appEventCenter.$off('changeHeaderSticky', this.setHeaderStickyState)
    appEventCenter.$off('picTopNavStatusChange', this.picTopNavStatusChange)
  },
  methods: {
    ...mapMutations([
      'assignRootState',
    ]),
    // 搜索框动画
    onLeave() { // 开始收起
      this.$emit('inputWidthShrink', true)
    },
    onAfterLeave() { // 完成收起
      this.$emit('inputWidthExpand', false)
    },
    onEnter() { // 开始展开
      this.$emit('inputWidthExpand', true)
      this.$emit('inputWidthShrink', false)
     
    },
    initScrollFixed() {
      const scrollFn = throttle({
        func: () => {
          if (this.$route.meta.notNeedCommonHeader) return // 不需要头部的页面不做处理
          this.storeRightCornerShowMore = false
          const scrollTop = document.documentElement.scrollTop
          this.showProductListSearchFixed(scrollTop)
          this.handelSearchSuspension(scrollTop)
        },
        wait: 10,
        options: { leading: true, trailing: true }
      })
      document.addEventListener('scroll', scrollFn, { passive: true })
      this.$once('hook:beforeDestroy', () => {
        document.removeEventListener('scroll', scrollFn)
      })
    },
    onResolveSearchTerms ({ info }) {
      this.wordTraceId = info?.trace_id || '-'
      if (this.isPickedInfoListPage) {
        window.sessionStorage.setItem('pickInfoListDefaultWords', JSON.stringify(info?.keywords ?? []))
      }
    },
    async handleWishlistShow() {
      const {
        ShowSearch = {},
        ListTitleType = {},
        Listshowsearch = {},
        ListShadingWord = {},
        flowshowsearch = {}
      } = await getUserAbtData()
      this.showSearchCategory = ShowSearch?.param == 'type=A' // 分类实验展示
      this.abtListshowsearch = Listshowsearch?.param?.Listshowsearch === 'show' // 真实+选品 列表
      this.abtRealListshowsearch =
        Listshowsearch?.param?.CListshowsearch === 'show' // 真实 列表
      this.abtSelectLsitshowsearch =
        Listshowsearch?.param?.SCListshowsearch === 'show' // 选品 列表
      this.abtPicksListshowsearch =
        Listshowsearch?.param?.PListshowsearch === 'show'
      this.abtListTitleTypeVal = ListTitleType?.param?.searchType || ''
      this.abtRealListTitleTypeVal = ListTitleType?.param?.csearchType || ''
      this.abtSelectListTitleTypeVal = ListTitleType?.param?.scsearchType || ''
      this.abtPicksListTitleTypeVal = ListTitleType?.param?.sheinpicks || ''
      this.abtListShadingWord = ListShadingWord?.param
      this.abtRecListFlowShowSearch = flowshowsearch?.param || {}
      // 获取到abt后设置顶部状态值
      this.setPageType(SaPageInfo?.page_name)
      this.showSearchFixed()
      setTimeout(() => {
        if (!this.$route.meta.notNeedCommonHeader) {
          // 曝光头部
          headerAnalysis().then(res => {
            // 店铺页头部曝光处理
            // v.趋势搜索结果页屏蔽该功能
            if (this.isStorePageOrSearchPage && !this.isTopTrendResultPage) {
              // 外露收藏 曝光收藏 + more
              res.exposeWishlist()
              if (!this.isStoreSearchPage) res.exposeMoreFunction()
              return
            }
            // v.趋势搜索结果页屏蔽该功能
            if (this.isTopTrendResultPage) {
              return
            }

            res.exposeWishlist()
          })
        }
      }, 3000) // 列表页等待page信息拼接
      window.addEventListener('scroll', this.preSearchScroll)
    },

    // 滑动的时候，处理搜索框悬浮的状态
    showProductListSearchFixed(scrollTop) {
      if (/pdsearch/.test(this.$route?.params?.pathMatch)) return
      let listSearchFixed = false

      if (this.isFiexdRuleShowInput || scrollTop > 40) {
        if (this.isSearchFixed) {
          listSearchFixed = true
        }
      } else {
        listSearchFixed = false
      }
      const curVal = this.catName || this.$route?.meta?.silInfo?.list_cat_name
      if (
        !this.isFiexdRuleShowInput &&
        listSearchFixed &&
        this.searchFixed != listSearchFixed &&
        scrollTop > 0 &&
        !this.isPickedInfoListPage
      ) {
        this.handleProductListSearchWords({
          searchword: curVal,
          transfer: 'scrollup searchFiexd'
        })
      }
      this.searchFixed = listSearchFixed
    },

    // 命中实验，点击筛选悬浮搜索框
    setHeaderStickyState(state) {
      if (!this.isSearchFixed) return
      this.searchFixed = state
      if (state) {
        this.handleProductListSearchWords({
          searchword: this.catName,
          transfer: 'click filter searchFiexed'
        })
      }
    },

    async dealingSearchInput() {
      if (this.isShowInput) {
        // 只有商详和首页会走这里触发底纹词的请求
        if (this.isGoodsDetailPage) {
          this.triggerGoodsDetailDefaultWordsReq()
        } else if (this.isConfigIndexPage) {
          this.triggerNormalDefaultWordsReq()
        } else if (this.isPickedInfoListPage) {
          this.triggerPickedInfoListDefaultWordsReq()
        }

        requestIdleCallback(async () => {
          const analysis = await searchAnalysis()
          if (this.searchEntranceBtnType === 'store') {
            analysis.exposeStoreSearch({
              Searchboxform: 2,
              search_content: ''
            })
          } else if (this.searchEntranceBtnType === 'brand') {
            analysis.exposeBrandSearch({
              Searchboxform: 2,
              search_content: this.searchSelectKeyWord
            })
          }
        })
      } else if (!this.$route?.meta?.notNeedCommonHeader) {

        // 解决直接刷新搜索结果页曝光 expose_search 两次
        const listType = this.$route?.meta?.type
        const searchType = this.$route?.query?.search_type
        const isListSearchResult = listType === 'search' && !['store', 'brand'].includes(searchType)
        if (isListSearchResult) {
          return
        }

        requestIdleCallback(async () => {
          const analysis = await searchAnalysis()
          if (this.searchEntranceBtnType === 'all') {
            if (SaPageInfo?.page_param?.search_content) {
              let payload = Object.assign(SaPageInfo.page_param, {
                currentWord: SaPageInfo.page_param.search_content
              })
              analysis.exposeSearch(payload)
            }
          }
        })
      }
    },

    // 列表场景展示搜索框时，对搜索词的展示处理
    handleProductListSearchWords({ searchword }) {
      const {
        cat_id,
        select_id,
        picks_id,
        child_cat_id: childCatId,
        childSelectId,
        isBackFromDetail
      } = this.catInfo || {}
      const userClickProductLatest = JSON.parse(
        window.sessionStorage?.getItem('userClickProductLatest') || '{}'
      )
      const { cat_id: detailBackCatId, goods_id: detailBackGoodsId } =
        userClickProductLatest || {}

      this.cacheListDefaultWordsKey = `${childCatId || cat_id || ''}_${
        childSelectId || ''
      }_${select_id || ''}_${picks_id || ''}`
      // 如果是商详返回的场景，并且能拿到对应商品的cat_id和goods_id的情况下，需要加上对应商品的catId和goodsId作为缓存的key
      if (isBackFromDetail && detailBackCatId && detailBackGoodsId) {
        this.cacheListDefaultWordsKey = `${this.cacheListDefaultWordsKey}_${detailBackCatId}_${detailBackGoodsId}`
      }
      // 命中底纹词展示策略直接请求底纹词
      const cacheListDefaultWordsValue = JSON.parse(
        window.sessionStorage?.getItem('cacheProductListSearchWordsReq') || '{}'
      )?.[this.cacheListDefaultWordsKey]

      // if (cacheListDefaultWordsValue) {
      // 解决真实跳选品再返回真实列表时，不更新底纹词逻辑
      this.listDefaultWordsProp = cacheListDefaultWordsValue 
      // }

      // @tips 历史代码指判断了 !this.listDefaultWordsProp 然而这里可能出现 [] 的情况，所以需要判断长度
      if (!this.listDefaultWordsProp || this.listDefaultWordsProp?.length === 0) {
        window.sessionStorage.removeItem('cacheProductListSearchWordsReq') // 清楚掉旧的缓存
        // 触发列表底纹词请求
        this.triggerListDefaultWordsReq(searchword)
      } else if (this.listDefaultWordsProp?.length > 0) {
        // 这个时候虽然触发了input的底纹词逻辑，但是不会触发请求，因为listDefaultWordsProp传给了input
        if (isBackFromDetail && detailBackCatId && detailBackGoodsId) {
          window.sessionStorage.removeItem('userClickProductLatest') // 已经请求过的清除掉，不然在页面进行category 或者 图文导航筛选的时候，因为这个时候 isBackFromDetail 是 true，所以会有问题
        }
      }
    },
    dealwithBffDefaultWordsResult({ pageTitleName, pageTitleInBlackList }) {
      // 更新当前页面词的map存储
      searchWordBlackReqCacheMap.set(pageTitleName, { state: pageTitleInBlackList })
      this.handleSearchWordsBlack({
        isBlackWrod: pageTitleInBlackList,
        searchword: pageTitleName,
      })  
    },

    // 触发商详页底纹词请求
    triggerGoodsDetailDefaultWordsReq() {
      this.cateIds = this.goodsDetailInfo?.catId || ''
      this.goodsId = this.goodsDetailInfo?.goodsId || ''
      this.selectId = ''
      this.bizScene = ''
      this.pageTitleName = ''
    },

    // 触发默认底纹词请求（目前只有首页和列表页的兜底会走）
    triggerNormalDefaultWordsReq() {
      this.goodsId = ''
      this.cateIds = ''
      this.selectId = ''
      this.bizScene = ''
      this.pageTitleName = ''
    },

    triggerPickedInfoListDefaultWordsReq() {
      const regex = /word_(.+)/
      const userClickProductLatest = JSON.parse(
        window.sessionStorage?.getItem('userClickProductLatest') || '{}'
      )
      // 实验入参需要做一定的转换，识别word_xxx作为接口对应入参，比如实验配置了listshading_word_recall要转换为对应接口的word_recall实验参数入参
      const newRule = {}
      Object.entries(this.abtListShadingWord).forEach(([key, value]) => {
        const match = regex.exec(key)
        if (match) {
          const newKey = match[0]
          newRule[newKey] = value
        }
      })
      this.goodsId = ''
      this.selectId = ''
      this.abtDefaultWords = newRule
      this.bizScene = 'informationLandingPage'
      this.cateIds = this.catInfo?.child_cat_id || this.catInfo?.cat_id || ''
      if (!this.cateIds && !this.selectId) {
        return
      }
      const { cat_id: detailBackCatId, goods_id: detailBackGoodsId } =
        userClickProductLatest || {}
      if (
        this.catInfo?.isBackFromDetail &&
        detailBackCatId &&
        detailBackGoodsId
      ){
        // 用户浏览过商品的情况下回到了列表场景
        this.cateIds = detailBackCatId
        this.goodsId = detailBackGoodsId
        if (this.cateIds && this.goodsId)
          debounce({ // 避免重复
            func: () => {
              window.sessionStorage.removeItem('userClickProductLatest') // 已经请求过的清除掉，不然在页面进行category 或者 图文导航筛选的时候，因为这个时候 isBackFromDetail 是 true，所以会有问题
            },
            wait: 1000
          })()
      }
      if (this.cateIds) {
        this.showDefaultWordsState = true
      }
    },

    // 触发真实/选品/picks底纹词请求(这些场景有对应的策略逻辑)
    triggerListDefaultWordsReq(pageTitleName) {
      const type = this.$route?.meta?.type
      const {
        cat_id,
        select_id,
        picks_id,
        childCatId,
        childSelectId,
        sub_type
      } = this.catInfo || {}
      const userClickProductLatest = JSON.parse(
        window.sessionStorage?.getItem('userClickProductLatest') || '{}'
      )
      this.cateIds = ''
      this.goodsId = ''
      this.selectId = ''
      this.bizScene = {
        entity: 'real',
        selection: 'select',
        picks: 'sheinpick',
      }[type]
      
      

      this.pageTitleName = pageTitleName
      // 商详返回到列表的场景
      const { cat_id: detailBackCatId, goods_id: detailBackGoodsId } =
        userClickProductLatest || {}
      if (
        this.catInfo.isBackFromDetail &&
        detailBackCatId &&
        detailBackGoodsId
      ) {
        // 用户浏览过商品的情况下回到了列表场景
        this.cateIds = detailBackCatId
        this.goodsId = detailBackGoodsId
        if (this.cateIds && this.goodsId)
          window.sessionStorage.removeItem('userClickProductLatest') // 已经请求过的清除掉，不然在页面进行category 或者 图文导航筛选的时候，因为这个时候 isBackFromDetail 是 true，所以会有问题
      } else {
        // childCatId / childSelectId 还在的情况，表示在对应的场景做过筛选，这个时候如果去到其它页面返回到列表场景的时候或者页面滑动的时候触发搜索框悬浮的情况还要用筛选过的类目去请求
        if (childCatId) {
          this.cateIds = childCatId
        } else if (childSelectId) {
          this.selectId = childSelectId
        } else {
          if (['entity', 'selection'].includes(type)) {
            if (type === 'entity') {
              this.cateIds = cat_id
            } else if (type === 'selection') {
              this.selectId = select_id
            }
          } else {
            const isSelection = sub_type === 'selection'
            if (isSelection) {
              this.selectId = picks_id
            } else {
              this.cateIds = picks_id
            }
          }
        }
      }
    },

    // 缓存列表场景最近请求过的底纹词
    cacheListDefaultWordsReq(args) {
      const { defaultWords } = args || {}
      window.sessionStorage.removeItem('cacheProductListSearchWordsReq')
      // 缓存请求过的入参
      window.sessionStorage?.setItem(
        'cacheProductListSearchWordsReq',
        JSON.stringify({ [this.cacheListDefaultWordsKey]: defaultWords })
      )
    },

    // 对用catName作为搜索词是否命中黑名单的处理
    handleSearchWordsBlack({ isBlackWrod = false, searchword }) {
      // 命中黑名单，请求首页策略的默认底纹词
      if (isBlackWrod) {
        this.assignRootState({
          searchSelectKeyWord: '',
          mixedKeyword: '',
          currentDefaultKeyword: ''
        })
        gbCommonInfo.keywords = ''
        this.currentKeyWord = ''
        this.isCatNameSearchWrods = false // 是否是用cateName作为搜索词，主要是埋点需要
        // 触发默认兜底底纹词请求
        this.triggerNormalDefaultWordsReq()
      } else {
        this.showDefaultWordsState = false
        this.currentKeyWord = searchword
        gbCommonInfo.keywords = searchword
        this.assignRootState({
          searchSelectKeyWord: searchword,
          mixedKeyword: searchword,
          currentDefaultKeyword: searchword
        })
        this.isCatNameSearchWrods = true // 是否是用cateName作为搜索词，主要是埋点需要
        searchAnalysis().then(analysis => {
          // 处理
          if(exposeSearchTextArr.includes(this.currentKeyWord)) {
            return 
          }
          analysis.exposeSearch({
            trace_id: this.wordTraceId,
            currentWord: this.currentKeyWord,
            isCatNameSearchWrods: this.isCatNameSearchWrods
          })
          exposeSearchTextArr.push(this.currentKeyWord)
        })
      }
    },
    bindEvent() {
      if (navigator.userAgent.indexOf('Safari') > -1) {
        // safari 手动触发链接跳转
        if ('standalone' in window.navigator && window.navigator.standalone) {
          var noddy
          document.addEventListener(
            'click',
            function (event) {
              noddy = event.target
              while (noddy.nodeName !== 'A' && noddy.nodeName !== 'HTML')
                noddy = noddy.parentNode
              if (
                'href' in noddy &&
                noddy.href.indexOf('http') !== -1 &&
                noddy.href.indexOf(document.location.host) !== -1
              ) {
                event.preventDefault()
                document.location.href = noddy.href
              }
            },
            false
          )
        }
      }

      if (window.SaPageInfo.page_name) {
        this.pageViewed = true
      }
      appEventCenter.$on('pageOnload', () => {
        this.pageViewed = true
        if (this.waitPageViewedToReport) {
          this.waitPageViewedToReport = false
          // this.exposedVisualSearch()
        }
      })
    },
    preSearchScroll: throttle({
      func: function () {
        // 显示快加车时，会将scroll置为0并且锁住滚动
        // 防止触发此函数导致：header search input > shein logo 切换
        const bodyDom = document.body
        const bodyStyle = bodyDom.style ?? {}
        const topValue = (bodyStyle.top ?? '').replace(/[^0-9]/gi, '')
        if (bodyStyle.position === 'fixed' && topValue > 250) return false
        const scrollTop = document.documentElement.scrollTop
        this.headerPresearchScroll = scrollTop > 250
      },
      wait: 100,
      options: { leading: true }
    }),
    setHeaderPresearchScroll(value) {
      this.headerPresearchScroll = !!value
    },
    uploadSuccessHandler(payload) {
      // 搜索框内容
      this.assignRootState({
        commonHeaderInput: payload
      })
      // 跳转到对应的中间页面
      this.goPresearch({ camera: 1, upload: true })
    },
    syncCurKeyword(curKeyword) {
      this.currentKeyWord = curKeyword
    },
    goPresearch(payload = {}) {
      markPoint('toNextPageClick', 'public')
      // 将当前轮换的默认词，显示在搜索页输入框内
      if (payload?.placeholder) {
        gbCommonInfo.keywords = ''
        this.assignRootState({
          currentDefaultKeyword: payload.placeholder.word,
          searchSelectKeyWord: '',
          mixedKeyword: '',
          inputDefaultWordsIndex: payload.defaultWords?.findIndex(i => i?.word === payload.placeholder?.word)
        })
      } else if (payload.currentWord) {
        // 搜索结果页带过去本次搜索的词
        gbCommonInfo.keywords = payload.currentWord
        gbCommonInfo.toPreSearchType = payload.type
        this.assignRootState({
          mixedKeyword: payload.currentWord,
          searchSelectKeyWord: payload.currentWord,
        })
      } else if (payload.type === 'clickclearBtn') {
        // 点了 删除icon 进去预搜页
        gbCommonInfo.keywords = ''
        this.assignRootState({
          mixedKeyword: '',
          // searchSelectKeyWord: '',
          // currentDefaultKeyword: ''
        })
      }
      // else {
      // gbCommonInfo.keywords = ''
      // this.assignRootState({
      //   mixedKeyword: '',
      //   searchSelectKeyWord: '',
      //   currentDefaultKeyword: ''
      // })
      // }

      // 标识进入新页面
      this.$route.meta.isNewPage = true

      // 点击相机的时候不需要发埋点
      if (payload?.camera) {
        this.$route.meta.cameraFrom = window.SaPageInfo.page_name
        this.$route.meta.upload = payload.upload
      } else {
        this.$route.meta.cameraFrom = null
        searchAnalysis().then(analysis => {
          if (this.searchEntranceBtnType === 'all') {
            if (SaPageInfo?.page_param?.search_content) {
              payload = Object.assign(SaPageInfo.page_param, {
                currentWord: SaPageInfo.page_param.search_content
              })
            }
            Object.assign(payload, {
              isCatNameSearchWrods: this.isCatNameSearchWrods
            })
            requestIdleCallback(() => {
              analysis.clickSearch(payload)
            })
          } else if (this.searchEntranceBtnType === 'brand') {
            const search_content = SaPageInfo?.page_param?.search_content ?? ''
            const payload = {
              search_content,
              search_box_form: search_content ? 2 : 1
            }
            analysis.clickBrandSearch(payload)
          } else if (this.searchEntranceBtnType === 'store') {
            const search_content = SaPageInfo?.page_param?.search_content ?? ''
            const payload = {
              search_content,
              search_box_form: 2
            }
            analysis.clickStoreSearch(payload)
          }
        })
      }
      const pagefrom = window?.SaPageInfo?.page_name
      const query = {
        pageType: this.searchEntranceBtnType,
        pagefrom
      }

      if (this.isGoodsDetailPage) {
        Object.assign(query, {
          goods_id: this.goodsDetailInfo?.goodsId,
          cate_id: this.goodsDetailInfo?.catId
        })
      }

      if (this.isTopTrendResultPage) {
        const entry_from = payload.type === 'clickclearBtn' ? 'page_search`-`-`search_clear_new' : 'page_search`-`-`search'
        Object.assign(query, {
          pageType: 'pageTrend',
          pagefrom: 'page_top_trend',
          entry_from,
        })
        
        if (payload.type === 'clickclearBtn') {
          // 趋势点击x 要清空底纹词
          // gbCommonInfo.keywords = ''
          // this.assignRootState({
          //   mixedKeyword: '',
          //   searchSelectKeyWord: '',
          //   currentDefaultKeyword: ''
          // })
        }
      }
      const src_identifier = getQueryString({ key: 'src_identifier' })
      if (src_identifier) {
        query.src_identifier = src_identifier
      }

      // 店铺搜索传入店铺id
      if (this.searchEntranceBtnType === 'store') {
        query.store_code = getStoreCodeFromUrl()
        query.brand_id = getQueryString({ key: 'brand_id' }) ?? ''
        query.searchExpand = getQueryString({ key: 'searchExpand' }) ?? ''
      } else if (this.searchEntranceBtnType === 'brand') {
        // 专题搜索页传tsp code
        query.intent_channel_id =
          getQueryString({ key: 'intent_channel_id' }) ?? ''
      }
      if (!payload.currentWord && payload.type === 'clickclearBtn') {
        query.show_association = '0'
        daEventCenter.triggerNotice({
          daId: '1-5-3-29',
          extraData: {
            pageType: this.$route?.meta?.pageType
          }
        })
      }
      query.Searchboxform = 2
      this.$router.push({
        path: `${this.langPath}/presearch`,
        query
      })
    },
    /**
     * 上传图片获取链接
     */
    async getImgLink(fileBlob, file) {
      const picFile = new window.File(
        [fileBlob],
        file.name.replace('webp', 'jpg'),
        { type: file.type.replace('webp', 'jpg') }
      )
      const formData = new FormData()
      formData.append('upload_img', picFile)
      let img = ''
      const data = await uploadImgBffApi(picFile)
      img = data?.info?.path || ''
      if (data?.code != 0) {
        throw new Error('图搜-上传图片bff-err', data)
      }
      if (img) {
        return transformImg({ img, useWebp: true, isImgCenter: true })
      }
      return ''
    },
    // 搜索结果页固定搜索框
    showSearchFixed() {
      if (/pdsearch/.test(this.$route?.params?.pathMatch)) {
        const { pathMatch = '' } = this.$route.params
        const curVal = pathMatch
          .replace(/(pdsearch)?\//g, '')
          .replaceAll('%25', '%')
        let mixedKeyword = curVal
        const filterWordsArr =
          getQueryString({ key: 'filterWords' })?.split(',') || []
        const filterAttrIdsArr =
          getQueryString({ key: 'filterAttrIds' })?.split('`') || []

        const searchFilterWords = []
        if (filterWordsArr.length) {
          filterWordsArr.forEach(filterWord => {
            const obj = { filterWord }
            const target = filterAttrIdsArr.find(_ => _.includes(filterWord))
            if (target) {
              const arr = target.split(',')
              const attrId = arr[1]
              const attrValueId = arr[2]
              if (attrId) {
                obj.attr_id = attrId
              }
              if (attrValueId) {
                obj.attr_value_id = attrValueId
              }
            }
            searchFilterWords.push(obj)
          })
          mixedKeyword = `${curVal} ${filterWordsArr.join(' ')}`
        }
        gbCommonInfo.keywords = mixedKeyword
        this.assignRootState({
          searchSelectKeyWord: curVal,
          mixedKeyword,
          searchFilterWords
        }) // 保证刷新页面值存在
        this.searchFixed = true
      }
    },

    checkIsPageStore(pageName) {
      const storePageNames = [
        'page_store_items',
        'page_store_brands',
        'page_store_category',
        'page_store_home',
        'page_store',
        'page_store_pre_search'
      ]
      const searchPageType = getQueryString({ key: 'pageType' })
      const isStoreSearch = searchPageType === 'store'
      const isPageStore =
        (storePageNames.includes(pageName) ||
          getQueryString({ key: 'search_type' }) === 'store' ||
          isStoreSearch) &&
        getStoreCodeFromUrl()
      return !!isPageStore
    },
    checkIsPageBrand() {
      const isPageCampaign = getQueryString({ key: 'search_type' }) === 'brand'
      return !!isPageCampaign
    },
    async setPageType(pageName) {
      // isPageStore 在店铺页面和店铺搜索结果页，都会为true
      const isPageStore = this.checkIsPageStore(pageName)
      const isPageCampaign = this.checkIsPageBrand()
      if (isPageStore) {
        this.searchEntranceBtnType = 'store'
      } else if (isPageCampaign) {
        this.searchEntranceBtnType = 'brand'
      } else {
        this.searchEntranceBtnType = 'all'
      }
    },
    pdsearchExposure() {
      // 进入店铺搜索页曝光外露 收藏/足迹
      headerAnalysis().then(res => {
        res.exposeWishlist()
      })
    },
    setCatInfo(catInfo) {
      this.catInfo = Object.assign({}, catInfo)
    },
    /**
     *
     * @param {string} listType entity | selection
     * @param {string} oldAbtValue 旧的实验配置值 包含真实和选品
     * @param {string} newAbtValue 新的实验配置值 真实和选品 单独配置
     * @param {string} fromPageType 来源页面类型 home | category
     * @param {string} entranceType 入口类型 h1 | sbc
     * @returns
     */
    isShowSearchHead({
      listType,
      oldAbtValue,
      newAbtValue,
      fromPageType,
      entranceType
    }) {
      let showSearchHead = false

      // 实验配置'c&sc'，不区分入口
      if (
        oldAbtValue === 'c&sc' ||
        newAbtValue === (listType === 'entity' ? 'clist' : 'sc')
      )
        showSearchHead = true
      // 实验配置'Category'，category过来的
      if (
        fromPageType === 'category' &&
        (oldAbtValue === 'Category' || newAbtValue === 'Category')
      )
        showSearchHead = true
      // 实验配置'home'，首页过来的
      if (
        fromPageType === 'home' &&
        (oldAbtValue === 'shop' || newAbtValue === 'shop')
      )
        showSearchHead = true
      // 实验配置'h1'，首页h1区域过来的
      if (
        entranceType === 'h1' &&
        (oldAbtValue === 'h1' || newAbtValue === 'h1')
      )
        showSearchHead = true
      // 实验配置'sbc'，首页sbc区域点击过来的
      if (
        entranceType === 'sbc' &&
        (oldAbtValue === 'sbc' || newAbtValue === 'sbc')
      )
        showSearchHead = true

      return showSearchHead
    },
    async getFirmSearchAbt() {
      const {
        flowfirmsearch,
        pfirmsearch,
        h1firmsearch,
        sbcfirmsearch,
        catefirmsearch,
        newsearch,
      } = await getUserAbtData()
      this.firmSearchAbt = {
        flowfirmsearch,
        pfirmsearch,
        h1firmsearch,
        sbcfirmsearch,
        catefirmsearch,
        newsearch,
      }
    },
    // 搜索框滑动延伸逻辑处理
    handelSearchSuspension(scrollTop) {
      if (this.showListSearchSuspension) {
        const filterDialogIsOpen = document?.body?.style?.overflow === 'hidden'
        // 弹窗打开会影响scrollTop的计算，需要屏蔽掉
        if (filterDialogIsOpen) return
        this.searchSrollHeight = this.isSmallStatus ? SEARCH_SCROLL_DISTANCE : Math.max(0, Math.min(SEARCH_SCROLL_DISTANCE, scrollTop))
        if((scrollTop > 1 || this.isSmallStatus) && this.searchSrollHeight > 0) {
          this.isSearchSticky = true
        } else {
          this.isSearchSticky = false
        }
      } else {
        this.isSearchSticky = false
        this.searchSrollHeight = 0
      }
    },
    picTopNavStatusChange(isSmallStatus) {
      const filterDialogIsOpen = document?.body?.style?.overflow === 'hidden'
      // 弹窗打开会影响scrollTop的计算，需要屏蔽掉
      if (this.showListSearchSuspension && !filterDialogIsOpen) {
        this.isSmallStatus = isSmallStatus
        if(isSmallStatus && this.searchSrollHeight > 0) {
          this.isSearchSticky = true
        } else {
          this.isSearchSticky = false
        }
      }
    }
  }
}
</script>

<style lang="less">
.expand-enter-active {
  animation: search-box-expand 0.35s forwards;
}
.expand-leave-active {
  animation: search-box-expand 0.35s ease-in-out reverse forwards;
}

.expand-enter-from,
.expand-leave-to {
  width: 5.57rem;
}

.expand-enter-to,
.expand-leave-from {
  width: 9.36rem;
}
@keyframes search-box-expand {
  0% {
    width: 5.57rem;
    opacity: 0;
  }
  100% {
    width: 9.36rem;
    opacity: 1;
  }
}
</style>
