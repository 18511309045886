import { ref, watch, computed, provide, onMounted } from 'vue'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'

export default function useSearchInput({ route, router }) {

  const isExpandSearch = ref(false) // 搜索框是否展开
  const isInputWidthExpand = ref(false) // 搜索框是否展开
  const isInputWidthShrink = ref(true) // 搜索框是否收缩
  const isInputAnimationEnabled = ref(true) // 是否开启动画
  const isFromOtherPage = ref(false)

  const isSearchBoxNeedExpandWidth = computed(() => {
    return isExpandSearch.value && isHomePage.value
  })

  provide('isSearchBoxNeedExpandWidth', () => isSearchBoxNeedExpandWidth.value)
  provide('isInputAnimationEnabled', () => isInputAnimationEnabled.value)

  const isHomePage = computed(() => {
    return route.value.name === 'config_index'
  })

  const handleInputWidthExpand = (isExpand) => {
    isInputWidthExpand.value = isExpand
  }

  const handleInputWidthShrink = (isShrink) => {
    isInputWidthShrink.value = isShrink
  }

  const getSearchBoxAbt = async () => {
    const { SearchBorderNew } = await getUserAbtData()
    isExpandSearch.value = SearchBorderNew?.param?.home_search_slide_expand === '1'
  }

  const removeAnimation = () => {
    const headerLeftDom = document.querySelector('.bsc-common-header__left')
    const headerRightDom = document.querySelector('.bsc-common-header__right')
    headerLeftDom.classList.remove('slide-in-from-left')
    headerRightDom.classList.remove('slide-in-from-right')
    headerLeftDom.classList.remove('slide-out-to-left')
    headerRightDom.classList.remove('slide-out-to-right')
  }

  const handleHeaderLeftAndRightStatus = (isShow) => {
    const headerLeftDom = document.querySelector('.bsc-common-header__left')
    const headerRightDom = document.querySelector('.bsc-common-header__right')
    const { GB_cssRight } = gbCommonInfo || {}
    if (isShow) {
      headerLeftDom.style.transform = 'translateX(0)'
      headerRightDom.style.transform = 'translateX(0)'
    } else {
      headerLeftDom.style.transform = `translateX(${GB_cssRight ? '+' : '-'}120%)`
      headerRightDom.style.transform = `translateX(${GB_cssRight ? '-' : '+'}120%)`
    }
  }

  const headerLeftAndRightAnimation = (isHide = false) => {
    const headerLeftDom = document.querySelector('.bsc-common-header__left')
    const headerRightDom = document.querySelector('.bsc-common-header__right')
    if (isHide) {
      headerLeftDom.classList.remove('slide-in-from-left')
      headerRightDom.classList.remove('slide-in-from-right')
      headerLeftDom.classList.add('slide-out-to-left')
      headerRightDom.classList.add('slide-out-to-right')
    } else {
      headerLeftDom.classList.remove('slide-out-to-left')
      headerRightDom.classList.remove('slide-out-to-right')
      headerLeftDom.classList.add('slide-in-from-left')
      headerRightDom.classList.add('slide-in-from-right')
    }
  }

  const handleSrcoll = () => {
    // 在首页滑动时，恢复搜索框的动画
    if (!isHomePage.value) return
    isFromOtherPage.value = false
    if (!isInputAnimationEnabled.value) {
      isInputAnimationEnabled.value = true
    }
  }

  const addSrcollListener = () => {
    requestAnimationFrame(() => {
      window.addEventListener('scroll', handleSrcoll, { once: true })
    })
  }

  const getShowAppHeader  = (routeName, routePath)=> {
    const mainPage = [
      'config_index',
      'page_category',
      'product-list-v2',
      'shein-picks',
      'page_coupon_product',
      'picked-info-list',
      'page_goods_detail',
      'config_activity',
      'page_flash_sale',
      'flash_sale',
      'feedback-rec-list',
      'campaigns',
      'page_advertising',
      'wishlistGroupShare',
      'page_store',
    ]
    const isActivityDesignerPage = routeName === 'config_activity' && routePath?.indexOf('designer') >= 0
    return mainPage.includes(routeName)
      && !isActivityDesignerPage
      && !route.meta?.notNeedCommonHeader
  }
 
  watch([() => isInputWidthShrink.value, () => isHomePage.value], ([newIsInputWidthShrink, newIsHomepage]) => {
    
    let scrollTop = 0
    requestAnimationFrame(() => {
      scrollTop = document.documentElement.scrollTop || window.scrollY
      // 如果回到首页到顶部，左右 icon 需要展示，兼容从其他页面点击底部tab回到首页的情况
      if (scrollTop <= 1) {
        handleHeaderLeftAndRightStatus(true)
        handleInputWidthExpand(false)
      }
    })
    if (isFromOtherPage.value && scrollTop <= 1) return
    // 搜索框展示时，隐藏左右 icon
    if (!newIsInputWidthShrink) {
      if (!isExpandSearch.value || !newIsHomepage) return
      headerLeftAndRightAnimation(true)
    } else {
      if (!newIsHomepage) return
      headerLeftAndRightAnimation(false)
    }
  })



  router.beforeEach((to, from, next) => {
    if (!isExpandSearch.value) {
      next()
      return
    }
    if (from.name === 'config_index' && to.name !== 'config_index') {
      // 离开首页到有公共 header 的页面，左右 icon 需要展示
      if (getShowAppHeader(to.name, to.path)) {
        removeAnimation()
        handleHeaderLeftAndRightStatus(true)
      }
      // 离开首页，当搜索框展开时, 需要收禁用搜索框动画，因为从其他页面返回首页时，搜索框不需要展开动画
      if (isInputWidthExpand.value) {
        isInputAnimationEnabled.value = false
        sessionStorage.setItem('isInputWidthExpand', isInputWidthExpand.value)
      }
    }
    // 进入首页
    if (from.name !== 'config_index' && to.name === 'config_index') {
      if (JSON.parse(sessionStorage.getItem('isInputWidthExpand'))) {
        handleInputWidthExpand(true)
        // isInputWidthExpand 只消费一次
        sessionStorage.removeItem('isInputWidthExpand')
        // 进入首页，当搜索框展开时，左右 icon 需要隐藏
        handleHeaderLeftAndRightStatus(false)
      }
    }
    next()
  })
  router.afterEach((to, from) => {
    if (from.name !== 'config_index' && to.name === 'config_index') {
      getSearchBoxAbt()
      addSrcollListener()
      isFromOtherPage.value = true
    }
    if (from.name === 'config_index' && to.name !== 'config_index') {
      removeAnimation()
      // 重置状态
      handleInputWidthShrink(true)
      handleInputWidthExpand(false)
    }
  })

  onMounted(() => {
    getSearchBoxAbt()
  })

  return {
    isSearchBoxNeedExpandWidth,
    isInputWidthExpand,
    handleInputWidthExpand,
    handleInputWidthShrink,
  }
}
