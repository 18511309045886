// import service from '../../../pages/product_app/store/modules/category/service'
import { transfromAndCutImg } from 'public/src/services/resource/index.js'
import {
  BANNER_DESIGN_WIDTH,
  CLSSIFY_DESIGN_WIDTH,
  SLIDER_LOGIN_DESIGN_WIDTH,
  SLIDER_STACK_DESIGN_WIDTH,
  TITLE_DESIGN_WIDTH,
  TWO_IMAGE_DESIGN_WIDTH,
} from '../../../pages/product_app/store/modules/category/const'

const screens = [
  [0,736,18],
  [736,1024,21],
  [1024,Infinity,24],
]
function getBffImgCount(){
  let count = 21
  if (typeof window === 'undefined') return count
  const height = window.screen.height
  for (let i = 0; i < screens.length; i++) {
    const range = screens[i]
    if(height > range[0] && height <= range[1]){
      count = range[2]
      break;
    }
  }
  return count
}

export function preLoadBffImgs(rightContent){
  const imgCount = getBffImgCount()
  let index = 0
  const images = []
  const list = rightContent?.contents || []
  if(list.length){
    for (let i = 0; i < list.length; i++) {
      const child = list[i]?.child?.[0] || {};
      if(child.componentKey === 'CATEGORY_COMPONENT' && child.props?.items?.length){
        const items = child.props.items
        for (let j = 0; j < items.length; j++) {
          const item = items[j]
          item.isLazy = index >= imgCount
          if(!item.isLazy){
            images.push({imgSrc: item.cover?.src, designWidth: CLSSIFY_DESIGN_WIDTH, item})
            index++
          }
        }
      }
    }
  }
  if(images.length > 0){
    // console.log('pre load', images);
    preLoadImagesInstall({ images })
  }
}

export function setBffLazyImg(rightContent){
  const imgCount = getBffImgCount()
  let index = 0
  const list = rightContent?.contents || []
  if(list.length){
    for (let i = 0; i < list.length; i++) {
      const child = list[i]?.child?.[0] || {};
      if(child.componentKey === 'CATEGORY_COMPONENT' && child.props?.items?.length){
        const items = child.props.items
        for (let j = 0; j < items.length; j++) {
          const item = items[j]
          item.isLazy = index >= imgCount
          index++
        }
      }
    }
    console.log(list, index);
  }
}

export function preLoadImagesInstall({ images }, cb) {
  const RESOURCE_SDK = gbCommonInfo.RESOURCE_SDK

  if (!RESOURCE_SDK) {
    return console.error('preLoadImagesInstall: RESOURCE_SDK is undefined')
  }

  let loadedNum = 0
  const imgLoadHandle = () => {
    loadedNum++
    if(loadedNum === images.length) {
      cb?.()
    }
  }

  const loadImage = (url, designWidth, item) => {
    const image = new Image()
    const {
      deviceData = '',
      isSupportWeb = '',
      isSupprotCut = false,
      sceneMap = {},
    } = RESOURCE_SDK || {}
    url = transfromAndCutImg({
      deviceData,
      isSupportWebp: Boolean(isSupportWeb),
      isSupprotCut,
      imgUrl: url,
      designWidth: Number(designWidth),
      sceneMap,
    })
    item._preloadUrl = url // 用于预加载图片的url
    image.onload = imgLoadHandle

    image.onerror = imgLoadHandle
    
    image.src = url
  }

  images.forEach(item => {
    loadImage(item.imgSrc, item.designWidth, item.item)
  })
}
