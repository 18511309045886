import { fetchDashboardBaseData, fetchAccInfo } from './fetcher'
import { transformImg, setLocalStorage, getLocalStorage } from '@shein/common-function'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import ReactiveData from './reactive-data'
import {
  PREFETCH_DATA_ROUTES_NAME,
  PREFETCH_WAIT_TIME,
  CACHEKEY_DASHBOARD_BASE_PREFIX,
  CACHEKEY_USERINFO,
  CACHEKEY_IMG_PRELOAD_PREFIX,
} from './constants'

import Cache from './cache'
import { handleNewUserBenefits } from 'public/src/pages/user/child_pages/dashboard/service/new-user-benefits'
// 暂时下架
// import { loadTdLibJs, __blackbox } from 'public/src/pages/common/RiskCommon/libs/BlackBox/index.js'


const { language: gbLanguage, IS_RW, PUBLIC_CDN, SiteUID, lang, /*BUYAPOWA_SITE,*/ SHOW_SOCIAL_RESPONSIBILITY_SITES } = gbCommonInfo

const DashboardCache = new Cache()

/**
 * 预取
 * 缓存
 * 设置初始化 setInitData
 * getInitData
 * useData ['cache', 'fresh']
 * 使用 useFreshData
 * 使用 useCacheData
 * 预处理数据
 */

class DashboardServcie {
  #prefetching = false
  #staticImgPrefetched = false // 图片只预取一次
  #dynamicImgPrefetched = false // 图片只预取一次
  
  /**
   * 路由跳转预取数据
   */
  async triggerRouterAfterEachPrefetch(to) {
    if (!PREFETCH_DATA_ROUTES_NAME.includes(to.name)) return // 非指定预取页面
    this.prefetch()
  }

  /**
   * 预取数据
   */
  async prefetch() {
    if (this.#prefetching) return
    this.#prefetching = true

    await wait(PREFETCH_WAIT_TIME)

    requestIdleCallback(async () => {
      // 静态图片预取
      this.handleStaticImgPrefetch()  
      // 接口数据预取
      const data = await this.useMainData({ cache: false, isLogin: isLogin() })
      this.#prefetching = false
      // this.setInitData(data)
      // console.log('[DashboardServcie] data prefetch success.')
      // 动态图片预取
      // this.handleDynamicImgPrefetch(data)

    })
  }

  /**
   * 静态图片预取，不依赖接口
   */
  handleStaticImgPrefetch() {
    if (this.#staticImgPrefetched) return
    const list = []
    handleImgPreload(list)
    this.#staticImgPrefetched = true
  }
  /**
   * 动态图片预取，依赖接口数据
   */
  handleDynamicImgPrefetch(data) {
    if (this.#dynamicImgPrefetched) return
    let list = []

    // 付费会员接口需要预取的
    if (data && data.primePannelSource) {
      list.push('https://img.ltwebstatic.com/images3_ccc/2024/06/27/1c/17194918134dcdc0cb0d831848658d3e21d5e1d4c4.png')
      list.push(`${PUBLIC_CDN}/pwa_dist/images/user/prime/shein_club_left_layer-b66d4a3575.png`)
      list.push(`${PUBLIC_CDN}/pwa_dist/images/sheinvip/vip_s0-b8caf01d39.png`)
      list.push(PUBLIC_CDN + '/pwa_dist/images/pay_vip/paid_vip_logo-088fc507b3.svg')
      list.push(PUBLIC_CDN + '/pwa_dist/images/pay_vip/paid_vip_logo_text-21a27b7d3f.png')
      list.push(PUBLIC_CDN + '/pwa_dist/images/pay_vip/pay_vip_split-eea3fc8ee3.png')
      list.push(PUBLIC_CDN + '/pwa_dist/images/user/prime/sui_img_limitedoffertag_atmosphere-f9524b35fe.svg')
      list.push(PUBLIC_CDN + '/pwa_dist/images/pay_vip/shein-club-bottom-arrow-1aa4262348.png')

      let primeDynamicImgs = []
      const { isPaid = false, recommendProduct = {}, primeInfo = {} } = data.primePannelSource

      if (isPaid) {
        primeDynamicImgs = primeInfo?.product_info?.right_config_list || []
      }
      primeDynamicImgs = recommendProduct?.right_config_list?.slice(0, 3) || []
      const primeDynamicImgsUrl = primeDynamicImgs.map(item => transformImg({ img: item?.right_type_icon || '' }))
      list = list.concat(primeDynamicImgsUrl)
    }
    // 超省卡需要预取的
    if (data && data.superSaveData) {
      list.push(PUBLIC_CDN + '/pwa_dist/images/user/extra/shein-saver-ea9b36164c.png')
      list.push(PUBLIC_CDN + '/pwa_dist/images/user/prime/renew_discount_bg_tips-e70005d314.png')
    }
    handleImgPreload(list)
    this.#dynamicImgPrefetched = true
  }

  /**
   * 使用聚合数据
   */
  async useMainData({ cache = false, isLogin, isRisk = false, isShouldUpdateWishlist = true }) {
    let dashboardBaseRes = null,
        userInfoRes = null

    // 使用缓存
    if (cache) {
      const { cacheKey } = await this.useFetchQueue({ isLogin, isRisk, isShouldUpdateWishlist })
      dashboardBaseRes = DashboardCache.get(cacheKey)
      userInfoRes = DashboardCache.get(CACHEKEY_USERINFO)
    }

    const fetchDashboardBase = dashboardBaseRes ? Promise.resolve(dashboardBaseRes) : this.doFetchDashboardBase({
      isLogin, isRisk, isShouldUpdateWishlist
    })
    const fetchUserInfo = userInfoRes ? Promise.resolve(userInfoRes) : this.doFetchUserInfo()

    fetchDashboardBase.then(res => dashboardBaseRes = res)
    fetchUserInfo.then(res => userInfoRes = res)

    await Promise.all([fetchDashboardBase, fetchUserInfo])
    return this.handleMainData({ dashboardBaseRes, userInfoRes, isLogin })
  }

  // TODO: 临时方案，后续需要优化
  setInitData(data) {
    ReactiveData.language = data.language
    ReactiveData.moreServices = data.moreServices
    ReactiveData.moreServiceConfig = data.moreServiceConfig
    ReactiveData.unReviewOrder = data.unReviewOrder
    

    ReactiveData.isOpenReviewEntry = data.isOpenReviewEntry
    ReactiveData.isOpenFreeTrialEntry = data.isOpenFreeTrialEntry
    ReactiveData.isOpenSheinxEntry = data.isOpenSheinxEntry
    ReactiveData.isShowFriendshipCouponEntry = data.isShowFriendshipCouponEntry
    ReactiveData.unpaidOrder = data.unpaidOrder

    ReactiveData.totalPoints = data.totalPoints  // 来自 UserOrdersList 路由
    ReactiveData.balances = data.balances
    ReactiveData.surveyNum = data.surveyNum
    ReactiveData.couponNum = data.couponNum

    ReactiveData.orderCount.processing = data.orderCount.processing || 0
    ReactiveData.orderCount.shipped = data.orderCount.shipped || 0
    ReactiveData.orderCount.unpaid = data.orderCount.unpaid || 0
    ReactiveData.orderCount.returns = data.orderCount.returns || 0
    ReactiveData.orderCount.review = data.orderCount.review || 0 // 很奇怪没看到 renderTriggered，可能入口还没渲染
    // SHEIN VIP
    if (data.vipData) {
      ReactiveData.isOldEsVip = data.vipData.isOldEsVip
      ReactiveData.vipSiteFrom = data.vipData.site_from
      ReactiveData.vipLevel = data.vipData.level
    }

    ReactiveData.showUserCenterNotice = !!data.showUserCenterNoticeText
    ReactiveData.showUserCenterNoticeText = data.showUserCenterNoticeText

    ReactiveData.primeData = data.primePannelSource
    ReactiveData.superSaveData = data.superSaveData

    ReactiveData.blackFiveConfig = data.blackFiveConfig
    ReactiveData.personalReward = data.personalReward

    ReactiveData.news = data.news
    ReactiveData.unpaidOrderAbt = data.unpaidOrderAbt
    ReactiveData.newUserBenefits = data.newUserBenefits
    ReactiveData.userRightsFusion = data.userRightsFusion
    ReactiveData.expireGameMoney = data.expireGameMoney
    ReactiveData.enableBottomTabRefresh = data.enableBottomTabRefresh
    ReactiveData.abtSource = data.abtSource
    ReactiveData.trendFlowAbt = data.trendFlowAbt
  }

  getInitData() {
    return ReactiveData
  }

  /**
   * 预处理数据
   */
  handleMainData({
    dashboardBaseRes,
    userInfoRes,
    isLogin
  }) {
    const dashboardBaseSource = dashboardBaseRes.code === '0' && dashboardBaseRes.info ? dashboardBaseRes.info : {}
    const abtSource = dashboardBaseSource.abtInfo || {}
    const userInfoSource = userInfoRes.code === '0' ? userInfoRes.info?.result : {}
    const primePannelSource = dashboardBaseSource.prime || {}
    const superSavePanelSource = dashboardBaseSource.superSaveData || {}

    const userInfoLanguage = userInfoSource.userInfo?.language || {}
    const userCenterNoticeText = dashboardBaseSource?.notice?.content || ''

    const isShowOrderResellEntrance = abtSource?.orderResellEntrance?.param === 'show_resellorder'
    const isOpenFreeTrialEntry = abtSource?.OpenFreeTrial?.param === 'type=B'
    
    const isShowFriendshipCouponEntry = abtSource?.FriendshipCoupon?.param?.FriendshipCoupon === 'VALUE=1'
    const isShowFollowing = abtSource?.storewishlist?.param?.storewishlist === 'show'
    // 店铺关注页特殊处理根据abt展示不同的icon --- 全量后更新配置
    const isShowNewFollowIcon = abtSource?.storeiconchange?.param?.storeiconchange === 'new'
    const isOpenReviewEntry = abtSource?.Reviewentry?.param === 'type=A'
    const isShowCouponOrPointsNotice = true

    const isMeCouponShow = abtSource?.MeCouponShow?.param?.MeCouponShow == 'on'

    const isShowFreeTrial = dashboardBaseSource.showFreeTrial

    const totalPoints = dashboardBaseSource.pointBalance || 0
    const balances = dashboardBaseSource.walletBalance || []
    const surveyNum = dashboardBaseSource.surveyNum || 0
    const couponNum = dashboardBaseSource.couponNum || 0
    const ticketNum = dashboardBaseSource.ticketNum || 0

    const vipData = dashboardBaseSource.vip || {}

    const orderCount = dashboardBaseSource.orderCount || {}

    const shareAndEarnAbtInfo = { show: false, route: '' }
    shareAndEarnAbtInfo.route = '/shareandearn'
    shareAndEarnAbtInfo.show = dashboardBaseSource.showShareandearn

    const isAdvocate = dashboardBaseSource.isAdvocate || {}

    const unpaidOrder = dashboardBaseSource.unpaidOrder || []
    const unReviewOrder = dashboardBaseSource.unReviewOrder || {}

    const blackFiveConfig = dashboardBaseSource.blackFiveConfig || {}
    const personalReward = {
      ...dashboardBaseSource?.personalReward,
      isMeCouponShow,
    }
    const checkFreeTrial = dashboardBaseSource.checkFreeTrial || {}
    const isMeClubTrailSwitch = abtSource?.MeClubTrailSwitch?.param?.MeClubTrailSwitch == 'on'
    const moreServiceConfig = dashboardBaseSource.moreServiceConfig
    const unpaidOrderAbt = {
      FloorType: abtSource?.MeOrderUnpaid?.param?.FloorType,
      PopType: abtSource?.MeOrderUnpaid?.param?.PopType,
    }
    console.log('%c moreService', 'color:blue;font-size:20px;', moreServiceConfig)

    const moreServices = [{
      id: 'support',
      title: gbLanguage.SHEIN_KEY_PWA_15039,
      val: Number(ticketNum),
      isShowBadget: Number(ticketNum) > 0,
      icon: 'sui_icon_me_support_24px',
      show: true,
      router: '/user/support',
      dataEventId: '1-16-1-6',
    }, {
      id: 'exchange',
      title: gbLanguage.SHEIN_KEY_PWA_21683,
      icon: 'sui_icon_me_exchange_24px',
      show: isShowOrderResellEntrance,
      router: '/exchange?open_from=4',
      dataEventId: '1-16-1-23',
      scence: 'exchange',
    }, {
      id: 'survey',
      title: gbLanguage.SHEIN_KEY_PWA_16269,
      icon: 'sui_icon_me_survey_24px',
      val: Number(surveyNum),
      isShowBadget: Number(surveyNum) > 0,
      show: true,
      router: '/user/survey',
      dataEventId: '1-16-1-7',
      scence: 'survey',
    }, {
      id: 'share&earn',
      title: gbLanguage.SHEIN_KEY_PWA_16636,
      icon: 'sui_icon_me_shareearn_24px',
      show: shareAndEarnAbtInfo.show,
      router: shareAndEarnAbtInfo.route,
      dataEventId: '1-16-1-1',
      scence: 'share',
    }, {
      id: 'freetrial',
      title: isOpenFreeTrialEntry ? gbLanguage.SHEIN_KEY_PWA_16268 : gbLanguage.SHEIN_KEY_PWA_16225,
      icon: 'sui_icon_me_freetrial_24px',
      show: !IS_RW && (isOpenFreeTrialEntry || !(!isOpenFreeTrialEntry && (lang == 'ma' || lang == 'maar' || !isShowFreeTrial))),
      router: '',
      dataEventId: '1-16-1-16'
    }, {
      id: 'My Reference',
      title: gbLanguage.SHEIN_KEY_PWA_16943,
      icon: 'sui_icon_me_myreference_24px',
      show: isLogin && isAdvocate.reference == 1,
      router: '/user/marketing/reference',
      dataEventId: '1-16-1-12'
    }, {
      id: 'Band Ambassador',
      title: gbLanguage.SHEIN_KEY_PWA_17637,
      icon: 'sui_icon_me_brandambassador_24px',
      show: isLogin && isAdvocate.promote == 1,
      router: '/user/marketing/ambassador'
    },
    {
      id: 'friendship_coupon',
      title: gbLanguage.SHEIN_KEY_PWA_20066,
      icon: 'sui_icon_me_friednshipcoupon_24px',
      show: !IS_RW && isShowFriendshipCouponEntry,
      router: '/friendship_coupon',
    }, {
      id: 'csr',
      title: gbLanguage.SHEIN_KEY_PWA_21997,
      icon: 'sui_icon_me_csr_24px',
      show: !IS_RW && SHOW_SOCIAL_RESPONSIBILITY_SITES.includes(SiteUID),
      router: '/product/article/1212',
      dataEventId: '1-16-1-28'
    },
    {
      id: 'following',
      title: gbLanguage.SHEIN_KEY_PWA_24068,
      icon: isShowNewFollowIcon ? 'sui_icon_me_follow_24px' : 'sui_icon_me_following_24px',
      show: !IS_RW && isShowFollowing,
      router: '/store/following',
      dataEventId: '1-16-1-29',
      scence: 'following',
    },
    {
      id: '14_Day_trial',
      title: gbLanguage.SHEIN_KEY_PWA_27306,
      icon: 'sui_icon_me_trial_24px', 
      show: isLogin && !IS_RW && isMeClubTrailSwitch && checkFreeTrial?.info?.can_trial == 1,
      router: '/user/primefreetrial?productsource=me',
      dataEventId: '1-16-1-35',
    },
    ]

    const userExpireInfo = getUserExpire({
      expirePonit: dashboardBaseSource.expirePonit,
      expireCoupon: dashboardBaseSource.expireCoupon,
      // expireCoupon: 10,
    })
    const newUserBenefits = handleNewUserBenefits(dashboardBaseSource.newUserBenefits)
    const { language, userRightsFusion, expireGameMoney } = dashboardBaseSource

    const result = {
      language,
      moreServices,
      moreServiceConfig,
      unpaidOrder,
      unReviewOrder,
      personalReward,
      blackFiveConfig,

      isOpenFreeTrialEntry,
      isOpenReviewEntry,
      isShowFriendshipCouponEntry,
      isShowOrderResellEntrance,
      isShowCouponOrPointsNotice,
      isShowFollowing,

      totalPoints,
      balances,
      surveyNum,
      couponNum: Number(couponNum),

      vipData,
      orderCount,

      userExpireInfo,

      showUserCenterNoticeText: userCenterNoticeText,

      accountInfo: userInfoSource,
      accountLanguage: userInfoLanguage,

      primePannelSource,
      superSaveData: {
        abtControl: abtSource.SaverSwitch?.p?.saverswitch === 'on',
        language: superSavePanelSource.language,
        detail: superSavePanelSource.extraInfo,
      },
      news: dashboardBaseSource.news || {},
      unpaidOrderAbt,
      newUserBenefits,
      userRightsFusion,
      expireGameMoney,
      enableBottomTabRefresh: abtSource?.pwameRecommend?.p?.PageMeTabRefresh === 'On',
      abtSource,
      trendFlowAbt: {
        pagemefeedtrendword: +abtSource?.pagemefeedtrend?.p?.pagemefeedtrendword || 0, 
        pagemefeedtrendcard: +abtSource?.pagemefeedtrend?.p?.pagemefeedtrendcard || 0, 
      }
    }
    this.setInitData(result)
    return result
  }

  /**
   * Base Request Fetch Queue
   */
  async useFetchQueue({ isLogin = false, isRisk = false, isShouldUpdateWishlist = true } = {}) {
    const fetchQueue = [
      'abt',
      'shareandearn',
      'prime',
      'blackFiveConfig',
      'personalReward',
      'news',
    ]

    if (isLogin) {
      fetchQueue.push('isAdvocate')
      fetchQueue.push('pointBalance')
      fetchQueue.push('vip')
      fetchQueue.push('walletBalance')
      fetchQueue.push('couponNum')
      fetchQueue.push('ticketNum')
      fetchQueue.push('surveyNum')
      // fetchQueue.push('userInfo')
      !IS_RW && fetchQueue.push('freeTrialRecord')
      fetchQueue.push('unpaidOrder')
      fetchQueue.push('unReviewOrder')
      fetchQueue.push('affiliateInfo') // [TR-31491] 个人中心增加推客入口

      if(!IS_RW) {
        fetchQueue.push('checkFreeTrial')
      }
     


      if (!isRisk) {  // 非风控
        fetchQueue.push('orderCount')
        if (isShouldUpdateWishlist) {
          fetchQueue.push('expirePonit')
          fetchQueue.push('expireCoupon')
          fetchQueue.push('notice')
        }
      }
    }
    
    const cacheKey = `${CACHEKEY_DASHBOARD_BASE_PREFIX}${isLogin}_${isRisk}_${isShouldUpdateWishlist}_${fetchQueue.join('_')}`

    return {
      fetchQueue: fetchQueue.reduce((result, current) => {
        result[current] = true
        return result
      }, {}),
      cacheKey: cacheKey
    }
  }

  /**
   * 请求用户信息
   */
  async doFetchUserInfo() {
    const result = await fetchAccInfo()
    // 更新缓存
    DashboardCache.set(CACHEKEY_USERINFO, result)
    return result
  }
  
  /**
   * 请求个人中心聚合接口
   */
  async doFetchDashboardBase({ isLogin, isRisk = false, isShouldUpdateWishlist = true }) {
    const { fetchQueue, cacheKey } = await this.useFetchQueue({ isLogin, isRisk, isShouldUpdateWishlist })
    const params = {
      blackbox: window?._fmOpt?.__blackbox || ''
    }

    const result = await fetchDashboardBaseData({ fetchQueue, params })
    // 更新缓存
    DashboardCache.set(cacheKey, result)
    return result
  }
}

/**
 * 获取用户即将过期积分 & 优惠券数量
 */
function getUserExpire({ expirePonit = 0, expireCoupon = 0 } = {}) {
  const { member_id } = gbCommonInfo.user
  const cacheKey = `${member_id}_${SiteUID}_${lang}_couponOrPointsData`
  const couponOrPointsData = parseCookieData(getLocalStorage(cacheKey))
  const { isReqData } = couponOrPointsData || {}
  const isClickCoupon = !!getLocalStorage(`${member_id}_couponHit`)
  const isClickPoints = !!getLocalStorage(`${member_id}_pointsHit`)

  if (isReqData) {
    return {
      couponOrPointsData,
      pointsOrCouponInfo: null,
      isReqData: 0,
      isClickCoupon,
      isClickPoints
    }
  }

  const pointsOrCouponInfo = {
    pointsNum: expirePonit,
    couponNum: expireCoupon,
  }
  setLocalStorage({
    key: cacheKey,
    value: JSON.stringify({
      ...couponOrPointsData,
      ...pointsOrCouponInfo,
      isReqData: 1,
      isClickCoupon,
      isClickPoints,
    }),
    expire: 60 * 5 * 1000
  })
  return {
    couponOrPointsData: null,
    pointsOrCouponInfo,
    isReqData: 1,
    isClickCoupon,
    isClickPoints
  }
}

const dashboardServcie = new DashboardServcie()

export default dashboardServcie

/* Utils */
function parseCookieData(data) {
  let tempData = {}
  if (data) {
    try {
      tempData = JSON.parse(data) || {}
    } catch (err) {
      tempData = {}
    }
  }
  return tempData
}

function wait(time = 0) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, time)
  })
}

/**
 * 图片预取
 */
function handleImgPreload(list = []) {
  list.forEach(item => {
    const cacheKey = CACHEKEY_IMG_PRELOAD_PREFIX + item
    const hasCache = DashboardCache.get(cacheKey)
    if (hasCache) return
    const imgObj = new Image()
    imgObj.src = item
    DashboardCache.set(cacheKey, true, { timeout: 1000 * 60 * 60 * 24 }) // 一天
  })
}
