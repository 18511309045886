import { prefetchUnderXPageData } from 'public/src/pages/under_x/api.js'
export const createUnderXRoute = (langPath = '') => {
  return {
    path: `${langPath}/under-x`,
    name: 'under_x',
    meta: {
      keepAlive: true,
      pageType: 'under_x',
      monitorTag: 'under-x',
      isCssInVue: true,
      notNeedCommonHeader: true,
    },
    component: () => import( /* webpackChunkName: "under_x" */ '../../under_x/App.vue'),
    beforeEnter: (to, from, next) => {
      if(typeof window !== 'undefined' && from?.name && to?.name === 'under_x'){
        prefetchUnderXPageData(to?.query)
      }
      next()
    },
  }
}
