import { TMGTriggerForRenderPage } from 'public/src/pre_requests/modules/thrifty_find.js'

export const createSuperDealsRoute = (langPath = '') => {
  return {
    path: `${langPath}/super-deals`,
    name: 'thrifty_find',
    meta: {
      keepAlive: true,
      pageType: 'thrifty_find',
      monitorTag: 'thrifty-find',
      isCssInVue: true,
      notNeedCommonHeader: true,
    },
    component: () => import( /* webpackChunkName: "thrifty_find" */ '../../thrifty_find/container.vue'),
    beforeEnter: (to, from, next) => {
      if(typeof window !== 'undefined'){
        if(from.name && from.name != 'thrifty_find'){
          TMGTriggerForRenderPage(to)
        }
      }
      next()
    },
  }
}
