import { getBffVersionBySsr } from 'public/src/pages/user/child_pages/user_bff_version/index.js'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'

export const createUserOrderRoute = (langPath = '', useOrderListBff) => {
  return [
    {
      path: `${langPath}/user/orders/detail/:billno`,
      name: 'UserOrdersDetail',
      meta: {
        keepAlive: false,
        pageType: 'ordersDetail',
        monitorTag: 'orders-detail',
        isCssInVue: true,
        notNeedCommonHeader: true
      },
      component: userOrderDetailRouteComponent
    },
    {
      path: `${langPath}/user/orders/virtualDetail/:billno`,
      name: 'UserOrdersVirtualDetail',
      meta: {
        keepAlive: false,
        pageType: 'virtualOrdersDetail',
        monitorTag: 'virtual-orders-detail',
        isCssInVue: true,
        notNeedCommonHeader: true
      },
      component: () =>
        import(
          /* webpackChunkName: "virtual_orders_detail" */ '../../user/child_pages/orders/virtualDetail/container.vue'
        )
    },
    {
      path: `${langPath}/user/orders/list`,
      name: 'UserOrdersList',
      component: userOrdersListRouteComponent(useOrderListBff),
      meta: {
        keepAlive: true,
        pageType: 'ordersList',
        monitorTag: 'order-list',
        isCssInVue: true,
        notNeedCommonHeader: true
      }
    }
  ]
}

export function userOrdersListRouteComponent(useOrderListBff) {
  return () => {
    const isBff = typeof window === 'undefined' && typeof useOrderListBff === 'boolean' ? useOrderListBff : getBffVersionBySsr('order_list_v1')
    return isBff ?
      prefetchResource.importAsyncComponent({
        chunkName: 'orders_list', // 跟webpackChunkName保持一致
        componentFactory: () => import(/* webpackChunkName: "orders_list" */ 'public/src/pages/user/child_pages/orders_bff/list/container.vue')
      })() : 
      prefetchResource.importAsyncComponent({
        chunkName: 'orders_list', // 跟webpackChunkName保持一致
        componentFactory: () => import(/* webpackChunkName: "orders_list" */ 'public/src/pages/user/child_pages/orders/list/container.vue')
      })()
  }
}

export function userOrderDetailRouteComponent() {
  return prefetchResource.importAsyncComponent({
    chunkName: 'orders_detail', // 跟webpackChunkName保持一致
    componentFactory: () => import(
      /* webpackChunkName: "orders_detail" */ 'public/src/pages/user/child_pages/orders/detail/container.vue'
    )
  })()
}

