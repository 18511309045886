import { prefetchLowestPricePageData } from 'public/src/pages/lowest_price/api.js'
export const createLowestPriceRoute = (langPath = '') => {
  return {
    path: `${langPath}/lowest-price`,
    name: 'lowest_price',
    meta: {
      keepAlive: true,
      pageType: 'lowest_price',
      monitorTag: 'lowest-price',
      isCssInVue: true,
      notNeedCommonHeader: true,
    },
    component: () => import( /* webpackChunkName: "lowest_price" */ '../../lowest_price/App.vue'),
    beforeEnter: (to, from, next) => {
      if(typeof window !== 'undefined' && from?.name && to?.name === 'lowest_price'){
        prefetchLowestPricePageData(to?.query)
      }
      next()
    },
  }
}
