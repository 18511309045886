
import { ref, computed, onMounted, nextTick } from 'vue'
import { useGlobalMapMutations } from './store.js'
import { useGlobalActions } from './store.js'
import { markPoint } from 'public/src/services/mark/index.js'
export default function useMessage({ route }) {
  const messageIconRef = ref(null)

  const showMessage = computed(() => {
    return ['config_index', 'page_category', 'wishlistGroupShare'].includes(
      route.value.name
    )
  })

  const { updateUnReadInfo } = useGlobalMapMutations(['updateUnReadInfo'])
  const { getCsChatMsgNumFirst, getMessageAbt } = useGlobalActions(['getCsChatMsgNumFirst', 'getMessageAbt'])

  const handleClickMessageIcon = () => {
    markPoint('toNextPageClick', 'public')
  }
  const fetchMessageData = async () => {
    await messageIconRef.value?.fetchUnreadMsg()
  }
  
  const sendBiData =  () => {
    const query = route.query || {}
    if (query.typeFlag) return
  
    const orders_if_unread = messageIconRef.value?.orderUnReadNum > 0 ? 1 : 0
    const news_if_unread = messageIconRef.value?.newsUnReadNum > 0 ? 1 : 0
    const activities_if_unread = messageIconRef.value?.activityUnreadNum > 0 ? 1 : 0
    const promo_if_unread = messageIconRef.value?.promoUnreadNum > 0 ? 1 : 0
    
    daEventCenter.triggerNotice({
      daId: '1-25-4-6',
      extraData: {
        orders_if_unread,
        news_if_unread,
        activities_if_unread,
        promo_if_unread
      }
    })
  }
  
  // 站内信 icon
  const messageHandler = () => {
    getMessageAbt()
    nextTick(() => {
      getCsChatMsgNumFirst()
    })
  }
  
  onMounted(() => {
    messageHandler()

    // 进入站内信页面，需要重新获取未读消息列表
    appEventCenter.$on('getMsgUnreadList', async () => {
      await fetchMessageData()
      nextTick(() => {
        updateUnReadInfo(messageIconRef.value?.messageInfo)
        sendBiData()
      })
    })
    // 在站内信页面清空消息后，需要重新获取未读消息列表
    appEventCenter.$on('allMsgClear', async () => {
      await fetchMessageData()
      nextTick(() => {
        updateUnReadInfo(messageIconRef.value?.messageInfo)
      })
    })
  })
  return {
    showMessage,
    messageIconRef,
    handleClickMessageIcon,
  }
}
