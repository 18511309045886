import schttp from 'public/src/services/schttp'

export function fetchLowestPricePage(params) {
  return schttp({
    url: '/api/lowest-price/get-lowest-price',
    method: 'GET',
    params: {
      selectId: params?.selectId,
      categoryId: params?.categoryId,
      adp: params?.adp
    }
  })
}

export async function fetchLowestPriceProducts(params) {
  return schttp({
    url: '/api/lowest-price/list/query',
    method: 'GET',
    params: {
      priceRange: params?.priceRange,
      categoryId: params?.categoryId,
      page: params?.page,
      limit: params?.limit,
      adp: params?.adp,
      selectId: params?.selectId,
      jsonRuleId: params?.jsonRuleId,
      filterOptions: {
        requireDiscountedGoods: true,
        noABPriceGoods: true
      }
    }
  })
}

export function prefetchLowestPricePageData(query) {
  const params = {
    selectId: query?.selectId,
    // categoryId: getQueryString('categoryId'),
    adp: query?.adp
  }

  const paramsChange = JSON.stringify(params) !== JSON.stringify(prefetchLowestPricePageData.__params__)

  prefetchLowestPricePageData.paramsChange = paramsChange

  // 参数不同，重新请求
  if(paramsChange) {
    prefetchLowestPricePageData.__params__ = params
    window.__lowestPricePageData__ = fetchLowestPricePage(params)
  }

  // 无数据时，请求数据
  if (!window.__lowestPricePageData__) {
    prefetchLowestPricePageData.__params__ = params
    window.__lowestPricePageData__ = fetchLowestPricePage(params)
  }

  return window.__lowestPricePageData__
}
