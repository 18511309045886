import { computed } from 'vue'
export default function useCommon({
  route,
  isStorePageOrSearchPage,
}) {
  const showSearchStoreNewStyle = computed(() => {
    return isStorePageOrSearchPage.value && route.value.routeName != 'page_store'
  })

  const pageScene = computed(() => {
    const { query = {}, meta = {} } = route.value
    const { search_type: searchType, pageType: searchPageType } = query || {}
    const { pageType, type: listType } = meta || {}
    if (searchPageType) { // 预搜页目前没有接入公共头
      switch(searchPageType) {
        case 'store':
          return 'storePreSearch' // 店铺预搜页
        case 'brand':
          return 'brandPreSearch' // 品牌预搜页 
        default:
          return 'allPreSearch' // 全局预搜页
      }
    }
    switch(pageType) {
      case 'category':
        return 'category'
      case 'index':
        return 'homeIndex' // 首页
      case 'productDetail':
        return 'goodsDetail' // 商详
      case 'page_store':
        return 'storePage' // 店铺
      case 'productList': // 列表
        switch(listType) { 
          case 'entity':
            return 'entityList' // 真实列表页
          case 'picks': // picks列表页
            return 'picksList'
          case 'selection': // 选品列表页
            if(searchType === 'store'){
              return 'storeSelectionList' // 店铺选品页
            }
            return 'selectionList' // 普通选品
          case 'search': // 搜索结果页
            if (searchType === 'store') {
              return 'storeSearchResult' // 店铺结果
            }
            if (searchType === 'brand'){ 
              return 'brandSearchResult' // 专题/品牌结果
            }
            return 'allSearchResult' // 全局结果
          default:
            return 'other'
        }
      default:
        return meta.pageType || 'other'
    }
  })

  return {
    showSearchStoreNewStyle,
    pageScene,
  }
}
