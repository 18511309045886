import isObject from 'lodash/isObject'

const { lang } = gbCommonInfo

const storeKey = `pwa_${lang}_storeSearchHistory`

const allSearchKey = `pwa_${lang}_searchHisotryWords`

const brandKey = `pwa_${lang}_brandSearchHistory`

const trendKey = `pwa_${lang}_trendSearchHistory`

const localKeyMap = {
  store: storeKey,
  brand: brandKey,
  pageTrend: trendKey
}

const _getData = (pageType) => {
  const searchHistoryMetadata = localStorage.getItem(localKeyMap[pageType])

  if (searchHistoryMetadata) {
    return JSON.parse(searchHistoryMetadata)
  } else {
    return {}
  }
}
/**
 * 获取特定的key搜索历史词
 * @param {*} key 门店id / tsp id
 * @returns String[]
 */
const getSearchHistoryByKey = (key, pageType) => {
  if (!key) return []

  const searchHistory = _getData(pageType)
  if (!searchHistory) return []

  if (searchHistory[key]) {
    return searchHistory[key]
  } else {
    return []
  }
}
/**
 * 设置门店id / tsp id的搜索词
 * @param {*} key 门店id / tsp id
 * @param {*} list 
 */
const setSearchHistoryByKey = (key, list = [], pageType) => {
  if (!key) return false
  const storeSearchHistory = _getData(pageType)

  storeSearchHistory[key] = list
  
  localStorage.setItem(localKeyMap[pageType], JSON.stringify(storeSearchHistory))
}

/**
 * 清除门店id / tsp id的搜索词
 * @param {*} key 门店id / tsp id
 */
const clearSearchHistoryByKey = (key, pageType) => {
  const storeSearchHistory = _getData(pageType)

  if (storeSearchHistory[key]) {
    delete storeSearchHistory[key]
  }

  localStorage.setItem(localKeyMap[pageType], JSON.stringify(storeSearchHistory))
}

/**
 * 根据查找模式获取不同的历史搜索词
 * @param {*} pageType all全站搜索  store门店搜索
 * @param {*} key 门店id / tsp id
 * @returns 
 */
export const getHistoryData = (pageType = 'all', key = '') => {
  if (pageType === 'all') {
    return JSON.parse(localStorage.getItem(allSearchKey)) || []
  } else if (pageType === 'pageTrend') {
    return JSON.parse(localStorage.getItem(trendKey)) || []
  } else {
    return getSearchHistoryByKey(key, pageType)
  }
}


/**
 * 根据查找模式插入单个历史搜索词
 * @param {*}  keywords 要增加的词
 * @param {*} pageType all全站搜索  store门店搜索
 * @param {*} key 门店id / tsp id
 * @param {*} index 删除的位置index
 * @returns 
 */
export const addHistoryData = ({ keywords, pageType = 'all', key = '', word_id }) => {
  if (!keywords) return false

  let searchHistoryWords = getHistoryData(pageType, key)

  const keyword = isObject(keywords) ? keywords.keywords : keywords

  searchHistoryWords = searchHistoryWords.filter( v => {
    const keywordHistoryItem = isObject(v) ? v.keywords || v.word : v
    return keywordHistoryItem !== keyword
  })
  if (pageType === 'all') { // 修改成存储对象
    searchHistoryWords.unshift({
      word: keyword,
      word_id,
      ...(isObject(keywords) ? keywords : null)
    })
  } else { // 其他依旧保持现状存 string
    searchHistoryWords.unshift(keywords)
  }

  // const maxLength = pageType === 'all' ? 30 : 15
  const maxLength = 15

  if (searchHistoryWords.length > maxLength) {
    searchHistoryWords = searchHistoryWords.slice(0, maxLength)
  }

  if (pageType === 'all') {
    localStorage.setItem(
      allSearchKey,
      JSON.stringify(searchHistoryWords)
    )
  }  else if (pageType === 'pageTrend') {
    localStorage.setItem(
      trendKey,
      JSON.stringify(searchHistoryWords)
    )
  } else {
    setSearchHistoryByKey(key, searchHistoryWords, pageType)
  }
}

/**
 * 根据查找模式删除单个历史搜索词
 * @param {*} pageType all全站搜索  store门店搜索
 * @param {*} key 门店id / tsp id
 * @param {*} index 删除的位置index
 * @returns 
 */
export const deleteSingleHistoryData = (pageType = 'all', key = '', index = 0) => {
  const searchHistoryWords = getHistoryData(pageType, key)

  searchHistoryWords.splice(index, 1)

  if (pageType === 'all') {
    localStorage.setItem(
      allSearchKey,
      JSON.stringify(searchHistoryWords)
    )
  } else if (pageType === 'pageTrend') {
    localStorage.setItem(
      trendKey,
      JSON.stringify(searchHistoryWords)
    )
  } else {
    setSearchHistoryByKey(key, searchHistoryWords, pageType)
  }
}

/**
 * 根据查找模式删除   all全部历史搜索词  store单个门店的历史搜索词
 * @param {*} pageType all全站搜索  store门店搜索
 * @param {*} key 门店id / tsp id
 * @returns 
 */
export const removeHistoryData = (pageType = 'all', key = '') => {
  if (pageType === 'all') {
    localStorage.removeItem(allSearchKey)
  } else if (pageType === 'pageTrend') {
    localStorage.removeItem(trendKey)
  } else {
    clearSearchHistoryByKey(key, pageType)
  }
}



