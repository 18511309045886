/**
 * @file 个人中心-性能优化相关
 */

import { transformImg } from '@shein/common-function'
import { ApiCache } from './ApiCache'
import { fetchFirstScreenData } from 'public/src/pages/user_index/api/index.js'
import { userIndexRouteComponent } from 'public/src/pages/product_app/router/user-router.js'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { getBffVersion } from 'public/src/pages/user/child_pages/user_bff_version/index.js'
import { preloadImg } from 'public/src/pre_requests/utlis/prefetch'

const userIndexApiCache = new ApiCache({
  cacheTime: 1 * 60 * 1000, // 缓存时间 1 分钟
  request: fetchFirstScreenData,
  notCacheKeys: [],
})

let isImgPreloaded = false
// 个人中心图片预取
const preloadUserIndexImg = () => {
  if (isImgPreloaded) {
    return
  }
  const { PUBLIC_CDN } = gbCommonInfo || {}

  const imgs = [
    'https://img.ltwebstatic.com/images3_ccc/2024/06/28/ba/171955373487ff0fedff3dc4bf018c781b5ecc55be.webp',
    'https://img.ltwebstatic.com/images3_ccc/2024/06/27/1c/17194918134dcdc0cb0d831848658d3e21d5e1d4c4.png',
    `${PUBLIC_CDN}/pwa_dist/images/pay_vip/paid_vip_logo-088fc507b3.svg`,
    `${PUBLIC_CDN}/pwa_dist/images/pay_vip/paid_vip_logo_text-21a27b7d3f.png`,
    `${PUBLIC_CDN}/pwa_dist/images/pay_vip/pay_vip_split-eea3fc8ee3.png`, // PNG 更小
    `${PUBLIC_CDN}/pwa_dist/images/user/prime/sui_img_limitedoffertag_atmosphere-f9524b35fe.svg`,
    `${PUBLIC_CDN}/pwa_dist/images/pay_vip/shein-club-bottom-arrow-1aa4262348.png`,
    `${PUBLIC_CDN}/pwa_dist/images/user/extra/shein-saver-ea9b36164c.png`,
    `${PUBLIC_CDN}/pwa_dist/images/user/prime/renew_discount_bg_tips-e70005d314.png`,
    `${PUBLIC_CDN}/pwa_dist/images/user/extra/left-layer-63d85c3d3a.png`,
    'https://img.ltwebstatic.com/images3_ccc/2024/09/04/e3/17254177090226b64bfd33a16bf4ce1dfab0e85407.png',
    'https://img.shein.com/images3/2022/09/22/16638345640e42fc3264d65029d43acf96e1c577f7.png',
    transformImg({ img: 'https://img.shein.com/images3/2024/05/30/c2/1717069622e0fb1ad94fe62fd6fadecda63fac3bb5.png' }),
    transformImg({ img: 'https://img.shein.com/images3/2024/05/30/1a/1717069626e8a2000b3e3c048488d6b8ec4c6417a4.png' }),
    transformImg({ img: 'https://img.shein.com/images3/2024/05/30/14/1717069630796c52075d945d13b1dc826556c3a8b5.png' }),
  ]
  preloadImg({ imgs, original: true })
  isImgPreloaded = true
}

/**
 * @description: 获取个人中心数据
 * */ 
async function handleFetchUserIndexApiCache() {
  preloadUserIndexImg()
  // 判断是否放量， 如果没有放量 直接返回
  if (!getBffVersion('userCenter_v1')) return null
  
  const params = {
    mid: UserInfoManager.get({ key: 'memberId', actionType: '/user_index' }) // 用于的用户态进行区分请求
  }
  return userIndexApiCache.request(params)
}

/**
 * @description: 个人中心页面请求前置处理
 * */
function handleUserIndexPreRequestBeforeEachRoute (to) {
  if (to.name === 'UserIndex') {
    handleFetchUserIndexApiCache()
  }
  if (to.name === 'config_index') { // 首页
    handleRuntimeUserIndexComponent()
  }
}

const execPreCallback = (fn) => {
  requestIdleCallback(() => {
    setTimeout(() => {
      fn()
    }, 3000)
  }, { timeout: 5000 })
}

let isHandled = false

// 预取执行个人中心组件编译执行
function handleRuntimeUserIndexComponent() {
  if (isHandled) return
  isHandled = true
  execPreCallback(() => {
    userIndexRouteComponent()
  })
}

// 空闲时进行图片预请求
execPreCallback(() => {
  preloadUserIndexImg()
  // 仅在首页空闲下进行接口预取，防止1分钟内下单退单数据缓存问题
  if (window.SaPageInfo?.page_name === 'page_home') {
    handleFetchUserIndexApiCache()
  }
})

export {
  handleFetchUserIndexApiCache,
  handleUserIndexPreRequestBeforeEachRoute,
}
