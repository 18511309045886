import { getSelectInfoByScId } from 'public/src/pages/cartNew/utils/fetcher.js'
import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index.js'
import { getQueryString, stringifyQueryString } from '@shein/common-function'
import storePageDataManager from 'public/src/pages/store_pages/js/storePageDataManager.js'
import { setFeedbackRecInfo } from 'public/src/pages/product_list_v2/js/utils'
import { getUserActionTrackerData } from 'public/src/pages/common/userActionTracker/core'


const { langPath } = typeof gbCommonInfo == 'undefined' ? {} : gbCommonInfo

// 融合完成后废弃 todo 
export const applyCoupon = (couponItem, $router, selectId = '') => {
  // console.log('======couponItem', couponItem)
  const { apply_for, category_id = '', coupon, scId = '', coupon_type_id, id } = couponItem
  // “全部商品”，点击则跳转到商品TAB页 / “部分商品”，则跳转到优惠券商品列表
  const offsetTop = document.querySelector(`.store-page-sticky`)?.offsetTop || 0
  const offsetHeight = document.querySelector(`.store-page-sticky`)?.offsetHeight || 0

  const coupon_type = 1
  const catIds = (category_id && category_id.join(',')) || ''
  const showTip = coupon_type_id == '2' ? 1 : 0
  switch (apply_for) {
    case '2':
      // 跳转可用商品弹窗, 去页面内请求数据
      $router.push(`${langPath}/user/coupon/add-items?code=${coupon}&typeId=${coupon_type_id}`)
      break
    case '3':
      // 跳转对应品类列表页，需要携带品类id category_id，打开新窗口
      // 如果coupon_type_id=2携带参数ShowTip=1&CouponCode={券码}
      $router.push(`${langPath}/coupon-product.html?ShowTip=${showTip}&CouponCode=${coupon.toLowerCase()}&multi_cat_ids=${catIds}&act_id=${id}&coupon_type=${coupon_type}`)
      break
    case '7':
      // 跳转选品页 scid
      // 如果coupon_type_id=2携带参数ShowTip=1&CouponCode={券码}
      $router.push(`${langPath}/coupon-sc-${scId}.html?ShowTip=1&CouponCode=${coupon.toLowerCase()}`)
      break
    case '1': {
      if (selectId) {
        getSelectInfoByScId(selectId).then(url => {
          if (url) $router.push(`${url}?comp=store-coupon&src_module=storeHomePage`)
        })
        return
      }
      window.scrollTo({
        top: offsetTop - offsetHeight,
        behavior: 'smooth',
      })
      break
    }
    default:
  }
}

export const getUseBffApi = (bffTag = 'storeHome_v1') => {
  const result = isSwitchBffApiVersion([bffTag])
  return result[bffTag]
}

export const removeURLParameter = (key) => {
  if (typeof window === 'undefined') return
  const currentUrl = new URL(window.location.href)
  currentUrl.searchParams.delete(key)
  // 使用 replaceState 修改当前历史条目的 URL 和状态
  window.history.replaceState({}, '', currentUrl.toString())
}

// 店铺开发提示log -- 仅仅在开发环境打印
export const storePageTipLog = ()=>{
  if(typeof window === 'undefined') return
  const { NODE_SERVER_ENV } = typeof gbCommonInfo == 'undefined' ? {} : gbCommonInfo
  // 本地开发
  if(NODE_SERVER_ENV === 'localhost' && !localStorage.IGNORE_STORE_PAGE_NEW_ACCESS_URL_LOG){
    // eslint-disable-next-line no-console
    console.log(`%c重磅-店铺新增访问方式: ${location.pathname}(店铺名称-store-storecode.html)`, 'color:red')
    // eslint-disable-next-line no-console
    console.log(' 1.旧的访问方式(store/home?store_code=xxxx)保留，后续将逐步向新的访问方式迁移,本地开发会重定向到新的访问方式链接\n',
      '2.新的访问方式无法从location.search中获取store_code, 所以在店铺页面禁止通过getQueryString获取store_code\n',
      '3.建议店铺中可通过store.getter中的catInfo 获取store_code, cccx装修组件通过sceneData.storeCode 获取\n',
      '4.新的店铺访问方式，中间层依然可以通过req.query.store_code 获取，客户端依然可以通过this.$route.query.store_code获取\n',
      '5.提供了兼容新旧店铺访问方式获取链接中store_code方法:getStoreCodeFromUrl和跳转到店铺方法：jumpToStorePage\n',
      '6.该log只在店铺本地开发时打印提示, 可通过执行 localStorage.IGNORE_STORE_PAGE_NEW_ACCESS_URL_LOG=1 屏蔽'
    )
  }
}

export const getStoreCodeByUrlString = (url) => {
  const reg = /.+-store-(\d{10})\.html/
  const matchResult = url.match(reg) || []
  return matchResult[1] || ''
}


/** 
 * 从url中获取 storeCode: 兼容从 location.search 和 location.pathname 中获取
*/
export const getStoreCodeFromUrl = () => {
  if(typeof window === 'undefined') return '' // 非客户端调用
  let storeCode = getQueryString({ key: 'store_code' })
  if(!storeCode && location.pathname){ // 从 /店铺名称-store-8789832178.html 中获取
    const reg = /.+-store-(\d{10})\.html$/ // store reg
    const matchResult = location.pathname.match(reg) || []
    storeCode = matchResult[1] || ''
  }
  return storeCode || ''
}



/** 
 * 跳转到店铺
 * params
*/
export const jumpToStorePage = (params, options)=>{
  if(typeof window === 'undefined') return // 非客户端调用
  const {
    isPreloadEnabled = true,
    replace = false, // push or replace
  } = options || {}
  const { store_name, store_code, ...restParam } = params || {}
  // 确保 传入 store_name 和 store_code
  if(!store_name || !store_code){
    throw new Error(`请确保在params中传入store_name和store_code`)
  }
  const { langPath = '', STORE_PAGE_STORE_CODE_IN_PATH_ACCESS = 'on' } = typeof gbCommonInfo == 'undefined' ? {} : gbCommonInfo
  let path = langPath
   
  let queryObj = restParam
  // apollo 控制
  if(STORE_PAGE_STORE_CODE_IN_PATH_ACCESS === 'on'){ // 推荐使用
    const _storeName = encodeURIComponent(store_name.trim().split(/\s+/g).join('-'))
    path += `/${_storeName}-store-${store_code}.html`
    queryObj = { store_code, ...restParam }
    path += `?${stringifyQueryString({ queryObj })}`
  }else{
    queryObj = { store_code, ...restParam }
    path += `/store/home?${stringifyQueryString({ queryObj })}`
  }
  const $router = window._gb_app_?.$router
  if($router){
    if (isPreloadEnabled) {
      storePageDataManager.prerenderRequest({
        fullPath: path,
        query: queryObj,
      })
    }
    if(replace){
      $router.replace(path)
    }else{
      $router.push(path)
    }
  }else{
    window.location.href = window.location.origin + path
  }
}




export function isOpenPrerenderStoreApp() {
  const isIos = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  if (isIos) {
    const screenWidth = window.screen.width
    const screenHeight = window.screen.height
    const iPhone8OrBelowSizes = [
      { width: 375, height: 667 }, 
      { width: 320, height: 568 }, 
    ]
    return iPhone8OrBelowSizes.some(size =>
      (screenWidth === size.width && screenHeight === size.height) ||
        (screenWidth === size.height && screenHeight === size.width) // landscape mode
    )
  }
  return true

}

/**
 * 点后刷场景
 * 处理allitem下 点击商卡进入商详后，返回店铺列表
 */
export const handleBackFromDetailRefreshList = async ({
  to,
  from,
  vm
}) => {
  const storeTabContainerRef = vm.$refs.TopTabApp?.$refs?.StoreTabContainer
  if (!storeTabContainerRef) return
  if (storeTabContainerRef.activeTab !== 'allItem') return
  const storeAllItemRef = storeTabContainerRef.$refs.storeAllItem
  if (!storeAllItemRef) return
  const productListInstance = storeAllItemRef.$refs.ProductList
  if (!productListInstance) return
  const isBackFromDetail = setFeedbackRecInfo({ productListInstance, path: from.path })
  if (!isBackFromDetail) return
  const requestEarlyBack = storeAllItemRef.getClickRefreshAbt('request_early_back')
  if (!requestEarlyBack) return
  // 如果不是用户点击商卡离开的页面，则不刷新
  if (storeAllItemRef.actionType !== 'click_product_item') return
  storeAllItemRef.removeUnexposedGoods()
  await storeAllItemRef.triggerComeBackRefreshList()
}


/**
 * 点后刷场景，参数处理
 */
export const handleClickRefreshParams = ({
  Results,
  listAbtResult,
  filterPrams,
}) => {
  const abtValue = listAbtResult?.StoreDivideTime?.p?.StoreDivideTime
  const withAiParams = !!listAbtResult?.AIFeatureSrv?.p?.ai_info_flow_cur_ft_config_url
  const enable = abtValue === 'request_early' || abtValue === 'request_early_back'
  const isClickRefresh = Number(filterPrams.isClickRefresh) === 1
  const exposedGoods = (Results?.exposedGoods || []).map(item => item.goods_id)
  const requstGoods = filterPrams.viewed_goods.split(',').map(item => item.split('-')[0])
  const ret = {
    featureParams: {
      post_click_refresh_enable: enable ? 1 : 0,
      post_click_refresh_request: isClickRefresh ? 1 : 0,
      list_exposed_goods: '',
      list_exposed_position: isClickRefresh ? exposedGoods.length : requstGoods.length,
      list_front_filter_goods: '',
      rt_f_list_cur_clk: '',
      rt_f_list_cur_cart: '',
    }
  }

  if (withAiParams) {
    const { rt_f_list_cur_clk, rt_f_list_cur_cart  } = getUserActionTrackerData('page_store')
    ret.featureParams.rt_f_list_cur_clk = rt_f_list_cur_clk.map(({ goods_id, timestamp }) => ([goods_id, timestamp])).flat(1)
    ret.featureParams.rt_f_list_cur_cart = rt_f_list_cur_cart.map(({ goods_id, timestamp }) => ([goods_id, timestamp])).flat(1)
  }
  if (!enable) return ret
  ret.featureParams.list_exposed_goods = isClickRefresh ? exposedGoods.join(',') : requstGoods
  ret.featureParams.list_front_filter_goods = Results?.frontFilterGoods || ''
  return ret
}
