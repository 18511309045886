
// 泛列表场景不在展示顶部购物车浮窗(abt开启),
export const LIST_TABLE = [
  'PTFeedbackRecList',
  'productList',
  'page_store',
  'PTRecProductList',
]

export const DA_CATEGORY_MAP = {
  index: 'Home',
  category: 'Category页',
  productDetail: '商品详情页',
  productList: '列表页',
  page_store: '列表页',
  flash_sale: '闪购页',
  activity: '专题页'
}

export const DA_CATEGORY_MAP2 = {
  page_coupon_product: '券可用商品承接页',
}

// 动画转场搜索框随手指滑动变化的距离
export const SEARCH_SCROLL_DISTANCE = 50
// 动画转场收藏、加车按钮随手指滑动变化的距离
export const ICON_SCROLL_DISTANCE = 10
