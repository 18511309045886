<script lang="jsx">
/**
 * @component 加车栏下的收藏按钮UI
 */
import { Icon } from '@shein-aidc/icon-vue2'

export default {
  name: 'WishBtnUI',
  functional: true,
  components: {
    Icon
  },
  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    // 收藏状态
    wishStatus: {
      type: [Boolean, Number],
      default: () => false,
    },
    // 处于开始收藏动画状态
    isStartAnimation: {
      type: Boolean,
      default: () => false,
    },
    // 处于结束收藏动画状态
    isEndAnimation: {
      type: Boolean,
      default: () => false,
    },
    // 添加至分组按钮是否展示
    showAddBoardBtn: {
      type: Boolean,
      default: () => false,
    },
    isRw: {
      type: Boolean,
      default: () => false,
    },
    loc: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: () => '32px',
    }
  },
  render(h, { props, listeners }) {
    const {
      language = {},
      wishStatus,
      isStartAnimation,
      isEndAnimation,
      showAddBoardBtn,
      isRw,
      size,
      loc
    } = props
    const ariaLabel = !!wishStatus
      ? language.SHEIN_KEY_PWA_15007
      : language.SHEIN_KEY_PWA_15006

    const handleBtnClick = (args) => {
      listeners.btnClick?.(args)
    }
    const handleAddBoard = (args) => {
      args?.stopPropagation?.()
      listeners.addBoard?.(args)
    }

    let totalClass = {
      'add-cart__wish-btn_anim-start': isStartAnimation && !isEndAnimation,
      'add-cart__wish-btn_anim-end': isEndAnimation,
    }

    // vue diff 优化会切换 name，Icon 不支持，这里手动实现v-show

    let icon = !isRw ? [
      <Icon name="sui_icon_save_completed_32px" size={size} class={totalClass} style={{ display: !!wishStatus ? 'inline-block' : 'none' }} />,
      <Icon name="sui_icon_save_32px" size={size} class={totalClass} style={{ display: !!wishStatus ? 'none' : 'inline-block' }} />
    ] : [
      <Icon name="sui_icon_save_completed_32px_a" size={size} color="#fc4070" class={totalClass} style={{ display: !!wishStatus ? 'inline-block' : 'none' }}  />,
      <Icon name="sui_icon_save_32px_a" size={size} class={totalClass} style={{ display: !!wishStatus ? 'none' : 'inline-block' }}  />
    ]

    return (
      <div
        class={{ 'add-cart__wish-btn j-btn-save': true, 'add-cart__wish-btn-bottom': loc !== 'topsite' }}
        {...{ directives: [{ name: 'ada', value: { level: 0, pos: 0 } }] }}
        role={language.SHEIN_KEY_PWA_17952}
        aria-label={ariaLabel}
        onClick={handleBtnClick}
      >
        {/* icon */}
        <div>
          { icon }
        </div>

        {/* 绝对定位的添加至分组按钮 */}
        {showAddBoardBtn
          ? (
            <div
              v-ada="{level: 0, pos: 0}"
              class={{ 'add-board': true, 'add-board-bottom': loc !== 'topsite', 'add-board-top': loc === 'topsite' }}
              onClick={handleAddBoard}
            >
              <i class="suiiconfont sui_icon_add_circle_18px"></i>
              { language.SHEIN_KEY_PWA_17403 }
            </div>
          )
          : null
        }
      </div>
    )
  }
}
</script>

<style lang="less">
.add-cart__wish-btn {
  background: #fff;
  position: relative;
  width: 0.85333rem;
  flex: 1 0 0.85333rem;
  height: 1.07rem;
  line-height: 1.07rem;
  text-align: center;
  -webkit-appearance: none;
  .suiiconfont {
    .font-dpr(68px);
  }
  .sui_icon_save_completed_32px,
  .sui_icon_save_completed_34px {
    color: @sui_color_brand;
  }
  &.add-cart__wish-btn-bottom {
    margin-right: 0.32rem;
  }
  &_anim-start {
    &::before {
      display: block;
      animation: goodsdt-like-anim-click 0.3s cubic-bezier(0.4, 0, 0.2, 1)
          forwards,
        goodsdt-like-anim-loading 0.8s cubic-bezier(0.4, 0, 0.2, 1) 0.3s
          infinite;
    }
  }
  &_anim-end {
    &::before {
      display: block;
      animation: goodsdt-like-anim-complete 0.2s ease both;
    }
  }

  .add-board {
    display: flex;
    position: absolute;
    min-width: 3.1067rem;
    height: 0.8533rem;
    line-height: 0.8533rem;
    background: #000;
    padding-left: 0.2666rem;
    padding-right: 0.32rem;
    color: #fff;
    font-size: 12px;
    opacity: 0.8;
    text-overflow: ellipsis;
    white-space: nowrap;
    .zindex-translatez(
      @zindex-attrmenusize,
      translate3d(0, 0, @zindex-attrmenusize)
    );
    /*rw:begin*/
    border-radius: 2px;
    [class*="iconfont"] {
      margin-right: 0.1067rem;
      vertical-align: -2px;
      font-size: 18px;
    }
  }
  .add-board-bottom {
    overflow: hidden;
    animation: add-board-keyframes-inside 500ms forwards ease;
    transform: translate3d(0, 0, 0);
    .left(0);
    top: calc(-1.1733rem - 0.16rem);
  }
  .add-board-top {
    animation: add-board-keyframes-top 500ms forwards ease;
    .right(0);
    top: 1.12rem;
    &::before {
      content: '';
      position: absolute;
      width: 0.2667rem;
      height: 0.2667rem;
      top: -0.13335rem;
      right: 10%;
      margin-left: -0.13335rem;
      background: #000000;
      transform: rotate(45deg);
      .zindex-translatez(
        @zindex-list-hdfilter,
        translate3d(0, 0, @zindex-list-hdfilter)
      );
    }
  }
  @keyframes add-board-keyframes-top {
    0% {
      opacity: 0;
      top: 0;
    }
    100% {
      opacity: 0.8;
      top: 1.12rem;
    }
  }
  @keyframes add-board-keyframes-inside {
    0% {
      opacity: 0;
      top: 0;
    }
    100% {
      opacity: 0.8;
      top: calc(-1.1733rem - 0.16rem);
    }
  }
}
</style>
