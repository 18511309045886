/* vue route path reg规则:
 * 禁止括号嵌套
 * /自动转\/, 任何\需手动转\\
 * https://github.com/vuejs/vue-router/blob/677f3c1f714fb61cc495345e535409b1cbb90429/src/create-route-map.js
 * 源码搜 regex: compileRouteRegex(normalizedPath, pathToRegexpOptions) 打包代码搜 regex:
 */
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import { LIST_PAGE_PATHS } from './constants.js'
import { getFetchSpaSsrPromise } from 'public/src/pre_requests/modules/productList/spaSsrHydration'

export const createListRoute = langPath => ({
  path: `${langPath}/(${LIST_PAGE_PATHS.join('|')})`,
  name: 'product-list-v2',
  component: prefetchResource.importAsyncComponent({
    chunkName: 'plv2_container', // 跟webpackChunkName保持一致
    componentFactory: async () => {
      return import( /* webpackChunkName: 'plv2_container' */ '../../product_list_v2/container.vue').then(r => {
        if (typeof window === 'undefined' || !getFetchSpaSsrPromise()) return r
        return getFetchSpaSsrPromise().then(() => r) // 单页水合预取html时，等待预取完成再解析
      })
    }
  }),
  meta: {
    keepAlive: true,
    isCssInVue: true,
    pageType: 'productList',
    monitorTag: 'product-list',
  },
})

export const createPicksRoute = () => ({
  path: `/*([a-z0-9]+-picks|romwe-[a-z]+|promo-discount).html`,
  name: 'shein-picks',
  meta: {
    keepAlive: true,
    isCssInVue: true,
    pageType: 'productList',
    // toSavePosition: ['page_goods_detail']
  },
  component: prefetchResource.importAsyncComponent({
    chunkName: 'shein_picks_container', // 跟webpackChunkName保持一致
    componentFactory: () => import(/* webpackChunkName: "shein_picks_container" */'../../shein-picks-v2/container.vue')
  }),
})

export const createUnderPricesListRoute = (langPath) => ({
  path: `${langPath}/under-prices-products.html`,
  name: 'under-prices',
  component: () => import(/* webpackChunkName: "under_prices" */'../../under-prices/Container.vue'),
  meta: {
    pageType: 'underPrices',
    keepAlive: true,
    isCssInVue: true,   // ?. 什么功能待定
    notNeedCommonHeader: true,    // 不需要公共头部
  }
})

export const createPickedInfoListRoute = (langPath) => [{
  path: `${langPath}/picked-info-products.html`,
  name: 'picked-info-list',
  component: () => import(/* webpackChunkName: "picked_info" */'../../rec_product_list/pickedInfo.vue'),
  meta: {
    keepAlive: true,
    isCssInVue: true,
    pageType: 'PTRecProductList',
    type: 'picked'
  }
}, {
  path: `${langPath}/feedback-rec-products.html`,
  name: 'feedback-rec-list',
  component: () => import(/* webpackChunkName: "feedback_rec" */'../../rec_product_list/feedbackRec.vue'),
  meta: {
    keepAlive: true,
    isCssInVue: true,
    pageType: 'PTFeedbackRecList'
  }
}, {
  path: `${langPath}/picked-products.html`,
  name: 'picked-products',
  component: () => import(/* webpackChunkName: "user-behavior" */'../../picked_products/container.vue'),
  meta: {
    keepAlive: true,
    isCssInVue: true,
    pageType: 'PTPickedNewProductList',
    notNeedCommonHeader: true,    // 不需要公共头部
  }
}]

export const createDiscountChannelListRoute = (langPath) => ({
  path: `${langPath}/discount-channel-products.html`,
  name: 'discount-channel-list',
  component: () => import(/* webpackChunkName: "discount-channel" */'../../discount_channel_list/discountChannel.vue'),
  meta: {
    keepAlive: true,
    isCssInVue: true,
    pageType: 'PTDiscountChannelList',
    notNeedCommonHeader: true,    // 不需要公共头部
  }
})
