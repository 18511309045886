import { debounce } from '@shein/common-function'
import { default as sa } from './sa'

export default {
  sa,
  parent_page: '',
  exposeSearch: debounce({
    func: function (payload) {  // todo: 需确认是否是箭头函数 this指向
      requestIdleCallback(() => {
        this.sa.exposeSearch(payload)
      })
    },
    wait: 1000
  }),
  clickSearch(payload) {
    requestIdleCallback(() => { this.sa.clickSearch(payload) })
  },
  exposeBrandSearch: debounce({
    func: function (payload) {  // todo: 需确认是否是箭头函数 this指向
      this.sa.exposeBrandSearch(payload)
    },
    wait: 1000
  }),
  exposeStoreSearch: debounce({
    func: function (payload) {  // todo: 需确认是否是箭头函数 this指向
      this.sa.exposeStoreSearch(payload)
    },
    wait: 1000
  }),
  search({ keyword, result_type, data, crowdId, originWord, pageType = 'all', trend_list_content, actionId, Searchboxform }) {
    requestIdleCallback(() => {
      this.sa.doSearch({ keyword, result_type, data, crowdId, originWord, pageType, trend_list_content, actionId, Searchboxform })
    })
  }
}
