import TMG from '@shein/time-management-guru'
import { getThriftyFindRenderPageData } from 'public/src/services/api/products'

export const TMGTriggerForRenderPage = (to) => {
  TMG.triggerQuickRequests('thriftyFind', {
    quickRequestNames: ['getThriftyFindPageData'],
    throttle: true,
    triggerParams: to.query
  })
}

// 获取不贵render数据
export const getThriftyFindPageData = async (params = {}) => {
  return await TMG.useQuickRequest(`thriftyFind/getThriftyFindPageData`, params)
}

// 不贵频道模块的预请求
export const thriftyFindRequests = {
  quickRequests: {
    getThriftyFindPageData: {
      params: ({ triggerParams }) => {
        return triggerParams
      },
      request: getThriftyFindRenderPageData,
    },
  },
}
