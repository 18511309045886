
import { ref, computed, watch } from 'vue'
export default function useLogo({ route, isSearchBoxNeedExpandWidth, isShowInput, isInputWidthExpand }) {
  const fromOtherPageShow = ref(false)
  
  const showLogo = computed(() => {
    if (isSearchBoxNeedExpandWidth.value) {
      if (fromOtherPageShow.value) {
        return true
      }
      return !isInputWidthExpand.value
    }

    return !isShowInput.value
  })

   
  watch(() => route.value.name, (newVal) => {
    if (newVal === 'config_index') {
      let scrollTop = 0
      requestAnimationFrame(() => {
        scrollTop = document.documentElement.scrollTop || window.scrollY
        // 如果回到首页到顶部，左右 icon 需要展示，兼容从其他页面点击底部tab回到首页的情况
        if (scrollTop <= 1) {
          fromOtherPageShow.value = true
        }
      })
    }
  })

  watch(() => isInputWidthExpand.value, (newVal) => {
    if (newVal) {
      fromOtherPageShow.value = false
    }
  })

  return {
    showLogo
  }
}
