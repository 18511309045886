<template>
  <SearchBox
    v-if="isMounted"
    :class="{
      'detailt-new-style': searchInputScene === 'goodsDetail' && searchBoxAbt.DetailShadingWord?.DetailNewSearch === 'new1',
    }"
    :searchBoxAbtStatus="searchBoxAbtStatus"
    :pageType="pageType"
    :searchBoxUI="getSearchBoxUI"
    :isShowInput="isShowInput"
    @searchTermClick="$emit('clickSearchBar', {...$event, defaultWords: searchTerms})"
    @textClick="$emit('clickSearchBar', {...$event, defaultWords: searchTerms})"
    @cameraIconClick="clickVisualSearch"
    @uploadImgSuccess="$emit('uploadSuccessHandler', $event)"
    @clearClick="$emit('clickSearchBar',{ type: 'clickclearBtn' })"
    @slideChangeTransitionEnd="slideChangeTransitionEnd"
    @resolveEmptySearchTerms="handleResolveEmptySearchTerms"
    @resolveSearchTerms="handleResolveSearchTerms"
  />
</template>

<script>
import { mapState } from 'vuex'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import analysis from '../analysis'
import { getQueryString } from '@shein/common-function'
import SearchBox from '../../../SearchBox'
import { getHistoryData } from 'public/src/pages/common/search_words/utils/searchHistoryLocalStorage.js'

const { language } = gbCommonInfo

daEventCenter.addSubscriber({ modulecode: '1-5-3' })

const exposeSearchTextArr = []

export default {
  name: 'LegacyCommonHeaderInput',
  components: {
    SearchBox,
  },
  props: {
    goodsId: { //【头部】商详goodsid
      type: String,
      default: ''
    },
    commonHeaderBg: { //【头部】沉浸式头部配置
      type: Object,
      default: () => ({
        backgroundColor: '', 
        searchInputBgColor: '', // 搜索框背景色
        topTextColor: '',
        showBlock: false
      })
    },
    searchInputBgColor: {
      type: String,
      default: ''
    },
    isCatNameSearchWrods: {  // 是否用catName作为搜索词
      type: Boolean,
      default: false
    },
    cateIds: {   // 底纹词策略接口需要的字段
      type: String,
      default: ''
    },
    selectId: { // 底纹词策略接口需要的字段
      type: String,
      default: ''
    },
    bizScene: {  // 给底纹词接口的场景名称
      type: String,
      default: ''
    },
    pageTitleName: {  // 给底纹词bff接口的页面名称 【选品、picks、真实】
      type: String,
      default: ''
    },
    pageType: {  // 页面类型 all, store, brand
      type: String,
      default: 'all'
    },
    isShowInput: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      isMounted: false,
      searchTerms: [],
      searchBoxAbtStatus: 'idle',
      // 搜索框实验
      searchBoxAbt: {
        SearchBorderNew: {},
        HomeSearch: {},
        NewPicSearch: {},
        PicSearchUpgrade: {},
        BackToSugg: {},
        newsearch: {},
        searchtrendpic: {},
        DetailShadingWord: {}
      },
      listShadingWordOn: true,
      fetchSearchTermsParams: {
        word_type: 1,
        scene: 'home'
      },
    }
  },
  computed: {
    // currentDefaultKeyword：框里的底纹词 searchSelectKeyWord：框里的搜索词 mixedKeyword：主搜词 +【分布式筛选词 (有就会拼上)】
    ...mapState(['currentDefaultKeyword', 'mixedKeyword', 'searchSelectKeyWord', 'searchFilterWords']),
    searchInputScene () { // 搜索框当前场景值
      const { $route = {} } = this
      const { query = {}, meta = {} } = $route
      const { search_type: searchType } = query || {}
      const { pageType, type: listType } = meta || {}
      switch(pageType) {
        case 'index':
          return 'homeIndex' // 首页
        case 'productDetail':
          return 'goodsDetail' // 商详
        case 'page_store':
          return 'storePage' // 店铺
        case 'productList': // 列表
          switch(listType) { 
            case 'entity':
              return 'entityList' // 真实列表页
            case 'picks': // picks列表页
              return 'picksList'
            case 'selection': // 选品列表页
              if(searchType === 'store'){
                return 'storeSelectionList' // 店铺选品页
              }
              return 'selectionList' // 普通选品
            case 'search': // 搜索结果页
              if (searchType === 'store') {
                return 'storeSearchResult' // 店铺结果
              }
              if (searchType === 'brand'){ 
                return 'brandSearchResult' // 专题/品牌结果
              }
              if (searchType === 'pageTrend') {
                return 'trendSearchResult' // 趋势搜索结果
              }
              return 'allSearchResult' // 全局结果
            default:
              return 'other'
          }
        case 'PTRecProductList': // 信息流落地页
          return 'rtRecProductList'
        default:
          return 'other'
      }
    },
    getSearchBoxUI() {
      const scene = this.searchInputScene
      console.log(`scene ::`, scene)

      // 当 abt 请求失败时，需要展示兜底的搜索框
      if (this.searchBoxAbtStatus !== 'resolved') {
        return {}
      }

      const { PicSearchUpgrade, DetailShadingWord } = this.searchBoxAbt
      if (scene === 'homeIndex') {
        const { SearchBorderNew, HomeSearch } = this.searchBoxAbt
        const historyData = getHistoryData('all').splice(0, 5)
        const wordIds = []
        const keywords = []
        historyData.forEach( i => {
          wordIds.push(i?.word_id || 0)
          if (typeof i === 'string') {
            keywords.push(i)
          } else {
            keywords.push(i.word.replace(',', ' '))
          }
        })
        return {
          component: 'DefaultSearchBox',
          autoFetch: true,
          boxType: this.getBoxType(SearchBorderNew.home_search_style),
          showSearchIcon: !Boolean(HomeSearch.HomeSearch),
          showCameraIcon: true,
          showSearchButton: Boolean(HomeSearch.HomeSearch),
          variableStyle: {
            '--search-box-box-height': '.90667rem',
            '--search-term-text-color': '#000',
            '--search-box-border-color': 'rgba(0, 0, 0, 0.6)',
            '--search-box-border-width': '.02667rem',
            '--search-box-search-button-color': 'rgba(0, 0, 0, 0.85)'
          },
          isCameraUploadImg: PicSearchUpgrade.PicSearchUpgrade === 'New',
          fetchSearchTermsParams: { 
            word_type: 1, 
            scene: 'home',
            recent_search_words: keywords.join(),
            recent_search_word_ids: wordIds.join(),
          },
          emptySearchTermsText: language.SHEIN_KEY_PWA_30497
        }
      }

      if (scene === 'allSearchResult') {
        const { newsearch, NewPicSearch } = this.searchBoxAbt

        // searchtrendpic
        // 命中searchtrendpic.show 或者 newsearch.Listnewsearch.old，不展示边框
        const boxType = newsearch.Listnewsearch === 'new' ? 'round' : 'none'

        const boxBorderStyle = this.commonHeaderBg?.showBlock ? { '--search-box-border-width': 0 } : {}

        const isTagMode = this.searchFilterWords.length > 0
        const searchSelectKeyWord = this.searchSelectKeyWord || ''

        const tagItem = searchSelectKeyWord ? {
          filterId: searchSelectKeyWord,
          filterWord: searchSelectKeyWord
        } : null
        const boxBgColor = this.searchInputBgColor || ([boxType === 'none', isTagMode].includes(true) ? '#f6f6f6' : '#fff')

        return {
          component: 'ActiveSearchBox',
          boxType,
          isTagMode,
          tagList: [tagItem, ...this.searchFilterWords].filter(Boolean),
          showCameraIcon: NewPicSearch.SearchResult === 'A',
          isCameraUploadImg: PicSearchUpgrade.PicSearchUpgrade === 'New',
          variableStyle: {
            '--search-box-box-height': '.90667rem',
            '--active-search-default-text-color': '#000',
            '--search-box-border-color': '#000',
            '--search-box-border-width': '.02667rem',
            '--search-box-bg-color': boxBgColor,
            ...boxBorderStyle
          },
          inputValue: this.searchSelectKeyWord,
          scene,
        }
      }

      if (scene === 'trendSearchResult') {
        const boxBorderStyle = this.commonHeaderBg?.showBlock ? { '--search-box-border-width': 0 } : {}

        return {
          component: 'ActiveSearchBox',
          boxType: 'round',
          showCameraIcon: false,
          variableStyle: {
            '--search-box-box-height': '.90667rem',
            '--active-search-default-text-color': '#000',
            '--search-box-border-color': '#000',
            '--search-box-border-width': '.02667rem',
            '--search-box-bg-color': '#fff',
            ...boxBorderStyle
          },
          inputValue: this.searchSelectKeyWord,
          scene,
        }
      }

      if (scene === 'goodsDetail') {
        // const { SearchBorderNew, HomeSearch } = this.searchBoxAbt
        const detailStyleMap = {
          'new1': 'right', // 点击icon去结果页
          'new2': 'left', // 默认点击icon去预搜页
          'old': 'old', // 老样式
          '': 'old', // 老样式
        }
        const iconBtnPosition = detailStyleMap[DetailShadingWord.DetailNewSearch]
        const goodsId = this.goodsId || this.$route.params['1']
        const scene = goodsId ? 'detail' : 'home'

        return {
          component: 'DefaultSearchBox',
          autoFetch: goodsId ? true : false,
          fixedFirstTerm: true,
          boxType: iconBtnPosition === 'old' ? 'none' : 'round',
          showSearchIcon: ['old', 'left'].includes(iconBtnPosition),
          showCameraIcon: !['left', 'right'].includes(iconBtnPosition),
          showSearchButton: ['right'].includes(iconBtnPosition),
          variableStyle: {
            '--search-box-box-height': '.90667rem',
            '--search-box-padding-right': ['left'].includes(iconBtnPosition) ? '12px' : '',
            '--search-term-text-color': '#000',
            '--search-box-border-color': iconBtnPosition === 'old' ? '#000' : '#E5E5E5',
            '--search-box-border-width': '.02667rem',
            '--search-box-search-button-color': ['right'].includes(iconBtnPosition) ? 'transparent' : '#000',
            '--search-box-search-button-round-padding-left': ['right'].includes(iconBtnPosition) ? '4px' : '',
            '--search-box-search-button-round-padding-right': ['right'].includes(iconBtnPosition) ? '10px' : '',
            '--search-box-search-icon-color': ['right'].includes(iconBtnPosition) ? '#222' : '',
            '--search-box-bg-color': iconBtnPosition === 'old' ? '' : '#F5F5F5',
            '--sui-icon-search-color': ['left'].includes(iconBtnPosition) ? '#222' : ''

          },
          isCameraUploadImg: PicSearchUpgrade.PicSearchUpgrade === 'New',
          fetchSearchTermsParams: { word_type: 1, goods_id: goodsId, scene, cat_id: this.cateIds  },
          emptySearchTermsText: language.SHEIN_KEY_PWA_30497
        }
      }

      if (scene === 'brandSearchResult') {
        return {
          component: 'ActiveSearchBox',
          boxType: 'none',
          isTagMode: false,
          showCameraIcon: false,
          // isCameraUploadImg: PicSearchUpgrade.PicSearchUpgrade === 'New',
          variableStyle: {
            '--search-box-box-height': '.90667rem',
            '--active-search-default-text-color': '#000'
          },
          inputValue: this.searchSelectKeyWord,
          scene,
        }
      }

      if (scene === 'picksList') {
        const showAbtParamsObj = {
          goods_id: this.goodsId,
          cat_id: this.cateIds,
          select_id: this.selectId,
          list_scene: this.bizScene,
          page_title_name: this.pageTitleName
        }

        if (!this.listShadingWordOn) {
          return {
            component: 'ActiveSearchBox',
            boxType: 'round',
            showClearIcon: false,
            showCameraIcon: true,
            showSearchButton: true,
            searchButtonType: 'round',
            isCameraUploadImg: PicSearchUpgrade.PicSearchUpgrade === 'New',
            variableStyle: {
              '--search-box-box-height': '.90667rem',
              '--active-search-default-text-color': '#000',
              '--search-term-text-color': 'rgb(34, 34, 34)'
            },
            inputValue: this.pageTitleName || this.searchSelectKeyWord,
            scene
          }
        }

        return {
          component: 'DefaultSearchBox',
          autoFetch: true,
          boxType: 'round',
          showSearchIcon: false,
          showSearchButton: true,
          showCameraIcon: true,
          variableStyle: {
            '--search-box-box-height': '.90667rem',
            '--search-term-text-color': '#000',
            '--search-box-border-color': '#000',
            '--search-box-border-width': '.02667rem',
            '--search-box-search-button-color': '#000'
          },
          isCameraUploadImg: PicSearchUpgrade.PicSearchUpgrade === 'New',
          fetchSearchTermsParams: {
            ...this.fetchSearchTermsParams,
            ...showAbtParamsObj,
          },
          emptySearchTermsText: language.SHEIN_KEY_PWA_30497
        }

      }

      if (scene === 'selectionList') {
        const showAbtParamsObj = {
          goods_id: this.goodsId,
          cat_id: this.cateIds,
          select_id: this.selectId,
          list_scene: this.bizScene,
          page_title_name: this.pageTitleName
        }

        if (!this.listShadingWordOn) {
          return {
            component: 'ActiveSearchBox',
            boxType: 'round',
            showClearIcon: false,
            showCameraIcon: true,
            showSearchButton: true,
            searchButtonType: 'round',
            isCameraUploadImg: PicSearchUpgrade.PicSearchUpgrade === 'New',
            variableStyle: {
              '--search-box-box-height': '.90667rem',
              '--active-search-default-text-color': '#000',
              '--search-term-text-color': 'rgb(34, 34, 34)'
            },
            inputValue: this.pageTitleName || this.searchSelectKeyWord,
            scene
          }
        }

        return {
          component: 'DefaultSearchBox',
          autoFetch: true,
          boxType: 'round',
          showSearchIcon: false,
          showSearchButton: true,
          showCameraIcon: true,
          variableStyle: {
            '--search-box-box-height': '.90667rem',
            '--search-term-text-color': '#000',
            '--search-box-border-color': '#000',
            '--search-box-border-width': '.02667rem',
            '--search-box-search-button-color': '#000'
          },
          isCameraUploadImg: PicSearchUpgrade.PicSearchUpgrade === 'New',
          fetchSearchTermsParams: {
            ...this.fetchSearchTermsParams,
            ...showAbtParamsObj,
          },
          emptySearchTermsText: language.SHEIN_KEY_PWA_30497
        }
      }

      if (scene === 'entityList') {
        const { newsearch, NewPicSearch } = this.searchBoxAbt
        const boxType = newsearch.Listnewsearch === 'new' ? 'round' : 'none'

        const showAbtParamsObj =  {
          goods_id: this.goodsId,
          cat_id: this.cateIds,
          select_id: this.selectId,
          list_scene: this.bizScene,
          page_title_name: this.pageTitleName
        }
        if (!this.listShadingWordOn) {
          return {
            component: 'ActiveSearchBox',
            boxType,
            showClearIcon: boxType === 'round' ? false : true,
            showCameraIcon: boxType === 'round' ? true :  NewPicSearch.SearchResult === 'A',
            showSearchButton: boxType === 'round' ? true : false,
            searchButtonType: 'round',
            isCameraUploadImg: PicSearchUpgrade.PicSearchUpgrade === 'New',
            variableStyle: {
              '--search-box-box-height': '.90667rem',
              '--active-search-default-text-color': '#000',
              '--search-term-text-color': 'rgb(34, 34, 34)'
            },
            inputValue: this.pageTitleName || this.searchSelectKeyWord,
            scene
          }
        }

        return {
          component: 'DefaultSearchBox',
          autoFetch: true,
          boxType,
          showSearchIcon: false,
          showSearchButton: boxType === 'round' ? true : false,
          showCameraIcon: boxType === 'round' ? true :  NewPicSearch.SearchResult === 'A',
          variableStyle: {
            '--search-box-box-height': '.90667rem',
            '--search-term-text-color': '#000',
            '--search-box-border-color': '#000',
            '--search-box-border-width': '.02667rem',
            '--search-box-search-button-color': '#000'
          },
          isCameraUploadImg: PicSearchUpgrade.PicSearchUpgrade === 'New',
          fetchSearchTermsParams: {
            word_type: 1,
            scene: 'home',
            ...showAbtParamsObj
          },
          emptySearchTermsText: language.SHEIN_KEY_PWA_30497,
        }
      }

      if (scene === 'storeSelectionList') {
        const boxType = 'round'
        const hotTermIndexObj = {
          showTermIcon: false,
          hotTermIndex: 2
        }
        const store_code = getQueryString({ key: 'store_code' })
        const autoFetch = store_code && getQueryString({ key: 'searchExpand' }) === '1'
        return {
          component: 'DefaultSearchBox',
          autoFetch: autoFetch,
          boxType,
          showSearchIcon: false,
          showSearchButton: boxType === 'none' ? false : true,
          showCameraIcon: false,
          searchButtonType: boxType,
          variableStyle: {
            '--search-box-box-height': '.90667rem',
            '--search-term-text-color': '#000',
            '--search-box-border-color': 'rgba(0, 0, 0, 0.8)',
            '--search-box-border-width': '.02667rem',
            '--search-box-search-button-color': 'rgba(0, 0, 0, 0.8)'
          },
          fetchSearchTermsParams: {
            word_type: 1,
            scene: 'store',
            store_code: store_code
          },
          ...hotTermIndexObj,
          emptySearchTermsText: language.SHEIN_KEY_PWA_30497
        }
      }

      if (scene === 'storeSearchResult') {
        return {
          component: 'ActiveSearchBox',
          boxType: 'round',
          isTagMode: false,
          showCameraIcon: false,
          // isCameraUploadImg: PicSearchUpgrade.PicSearchUpgrade === 'New',
          variableStyle: {
            '--search-box-box-height': '.90667rem',
            '--active-search-default-text-color': '#000'
          },
          inputValue: this.searchSelectKeyWord,
          scene
        }
      }

      if (scene === 'rtRecProductList') {
        const { NewPicSearch } = this.searchBoxAbt
        const boxType = 'round' 

        const showAbtParamsObj =  {
          goods_id: this.goodsId,
          cat_id: this.cateIds,
          select_id: this.selectId,
          list_scene: this.bizScene,
          page_title_name: this.pageTitleName
        }
        return {
          component: 'DefaultSearchBox',
          autoFetch: !!(this.bizScene && this.cateIds),
          boxType,
          showSearchIcon: false,
          showSearchButton: boxType === 'round' ? true : false,
          showCameraIcon: boxType === 'round' ? true :  NewPicSearch.SearchResult === 'A',
          variableStyle: {
            '--search-box-box-height': '.90667rem',
            '--search-term-text-color': '#000',
            '--search-box-border-color': '#000',
            '--search-box-border-width': '.02667rem',
            '--search-box-search-button-color': '#000'
          },
          isCameraUploadImg: PicSearchUpgrade.PicSearchUpgrade === 'New',
          fetchSearchTermsParams: {
            word_type: 1,
            scene: 'home',
            ...showAbtParamsObj
          },
          emptySearchTermsText: language.SHEIN_KEY_PWA_30497,
        }
      }

      // const defaultSearchBoxUI = {
      //   component: 'DefaultSearchBox',
      //   boxType: 'none',
      //   showSearchIcon: true,
      //   showSearchButton: false,
      //   variableStyle: { '--search-term-text-color': '#000' }, 
      //   fetchSearchTermsParams: { word_type: 1, scene: 'home' }
      // }

      // console.log(`---------> not sence ::`, scene)

      return {}
    },
    changeInputAndSearch() {
      return {
        isShowInput: this.isShowInput,
        searchTerms: this.searchTerms,
      }
    }
  },
  watch: {
    changeInputAndSearch: {
      handler ({ isShowInput }) {
        // 此处曝光需要考虑 搜索框展示 与 底纹词返回之间的先后关系
        // 同时watch两者的变化才能准确曝光
        if (isShowInput) {
          this.handleExposeAnalysis()
        }
      },
      immediate: true,
      deep: true
    },
    searchInputScene: {
      handler(newVal){
        // v.趋势搜索结果页屏蔽该功能
        if(newVal === 'allSearchResult' || newVal === 'trendSearchResult') {
          // 搜索结果页没有底纹词，不会因为底纹词而出发exposeSearch，在这里手动触发
          // exposeSearch有时比结果页的pv先发，所以手动修改page_name
          const srcIdentifier = getQueryString({ key: 'src_identifier' }) ?? ''
          const searchType = (srcIdentifier.match(/st=(\d+)`/) || [])[1] || ''
          requestIdleCallback(() => {
            SaPageInfo.page_name = 'page_search'
            sa('set', 'setPageData', SaPageInfo)

            if (newVal === 'trendSearchResult') {
              analysis.exposeSearch({
                currentWord: this.currentDefaultKeyword || this.mixedKeyword,
                isCatNameSearchWrods: this.isCatNameSearchWrods,
                result_type: searchType,
                src_module: 'trend_search',
              })
              return
            }

            analysis.exposeSearch({
              currentWord: this.currentDefaultKeyword || this.mixedKeyword,
              isCatNameSearchWrods: this.isCatNameSearchWrods,
              result_type: searchType
            })
          })
        }
      },
      immediate: true
    },
  },
  created() {
    this.initSearchBoxAbt()

    this.exposeVisualSearch = false
  },
  mounted() {
    this.hackFixedSwiper()
  },
  methods: {
    hackFixedSwiper() {
      // 修复搜索框轮播词不显示问题
      setTimeout(() => {
        this.isMounted = true
      })
    },
    async initSearchBoxAbt() {
      this.searchBoxAbtStatus = 'pending'
      const { 
        SearchBorderNew = {},
        HomeSearch = {},
        NewPicSearch = {},
        PicSearchUpgrade = {},
        BackToSugg = {},
        newsearch = {},
        searchtrendpic = {},
        DetailShadingWord = {}
      } = await getUserAbtData()

      this.searchBoxAbt = {
        SearchBorderNew: SearchBorderNew.p || {},
        HomeSearch: HomeSearch.p || {},
        NewPicSearch: NewPicSearch.p || {},
        PicSearchUpgrade: PicSearchUpgrade.p || {},
        BackToSugg: BackToSugg.p || {},
        newsearch: newsearch.p || {},
        searchtrendpic: searchtrendpic.p || {},
        DetailShadingWord: DetailShadingWord.p || {}
      }

      this.searchBoxAbtStatus = 'resolved'
    }, 
    getBoxType(params) {
      if (['new', 'white'].includes(params)) {
        return 'square'
      }
      if (params === 'round') {
        return 'round'
      }
      return 'none'
    },

    handleResolveEmptySearchTerms({ params, info }) {
      if (!['entityList', 'picksList', 'selectionList'].includes(this.searchInputScene)) {
        return
      }
      const inBlackList = Boolean(-info?.is_black_list)
      const defaultWordRequestScene = params.list_scene
      if (inBlackList && defaultWordRequestScene) {
        // 列表底纹词空，且列表title在黑名单里
        this.listShadingWordOn = true
        this.fetchSearchTermsParams = {
          word_type: 1,
          scene: 'home'
        }
        this.$emit('cacheListDefaultWordsReq', { defaultWords: [] })
        this.$emit('dealwithBffDefaultWordsResult', {
          pageTitleName: this.pageTitleName,
          pageTitleInBlackList: true,
        })
      } else if (!inBlackList && defaultWordRequestScene) {
        // 列表底纹词空，且列表title【不在】黑名单里
        this.listShadingWordOn = false // defaultbox -> activebox
        this.$emit('cacheListDefaultWordsReq', { defaultWords: [] })
        this.$emit('dealwithBffDefaultWordsResult', {
          pageTitleName: this.pageTitleName,
          pageTitleInBlackList: false,
        })

      } else if (!defaultWordRequestScene) {
        // 列表、全局底纹词均为空，且列表title在黑名单里
      }
    },

    handleResolveSearchTerms({ searchTerms, info }) {
      this.searchTerms = searchTerms
      this.$emit('resolveSearchTerms', { info })
      if (['entityList', 'picksList', 'selectionList'].includes(this.searchInputScene)) {
        this.$emit('cacheListDefaultWordsReq', { defaultWords: searchTerms })
      }
    },
    clickVisualSearch() { // 相机按钮点击、打开中间页
      daEventCenter.triggerNotice({
        daId: '1-5-3-1',
      })
      this.$emit('initMiddlePage')
    },
    handleExposeAnalysis() {
      // 处理第一个轮播词曝光...
      const firstSearchTerm = this.searchTerms[0]?.word
      if (this.searchTerms.length && !exposeSearchTextArr.includes(firstSearchTerm)) {
        analysis.exposeSearch({
          defaultWords: [this.searchTerms[0]],
          placeholder: this.searchTerms[0],
          localIndex: 0,
        })
        exposeSearchTextArr.push(firstSearchTerm)
      } else if (this.searchInputScene === 'rtRecProductList') { // 信息流落地页无词也曝光
        analysis.exposeSearch({
          defaultWords: [],
          placeholder: '',
          localIndex: 0,
        })
      } else if (!this.searchTerms.length && this.searchInputScene === 'goodsDetail') { // 商详没展示底纹词词也要曝光
        analysis.exposeSearch({
          defaultWords: [],
          placeholder: '',
          localIndex: 0,
        })
      }

      // 处理相机曝光...
      if (!this.exposeVisualSearch) {
        // v.趋势搜索结果页屏蔽该功能
        if (this.searchInputScene === 'trendSearchResult') return

        this.$nextTick(this.handleVisualSearchAnalysis)
        this.exposeVisualSearch = true
      }
    },
    // 轮播曝光埋点
    slideChangeTransitionEnd(index, item) {
      if (exposeSearchTextArr.includes(item?.word)) {
        return
      }
      analysis.exposeSearch({
        defaultWords: [item],
        placeholder: this.searchTerms[0],
        localIndex: index,
      })
      exposeSearchTextArr.push(item?.word)
    },
    handleVisualSearchAnalysis() {
      if (this.$route.name === 'pre_search') return // 仅在结果页上报
      // 除了趋势搜索/品牌搜索 都有图搜
      if (!['brandSearchResult', 'trendSearchResult', 'storeSearchResult'].includes(this.searchInputScene)) {
        requestIdleCallback(() => {
          daEventCenter.triggerNotice({
            daId: '1-5-3-2',
          })
        })
      }
    }
  }
}
</script>
