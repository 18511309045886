/**
 * @file 榜单落地页
 */

/**
 *  热销榜、折扣榜
 * @param {string} langPath 
 * @returns 
 */
export const createRankProductsRoute = langPath => ({
  path: `${langPath}/ranking_list/page`,
  name: 'page_rank_products',
  meta: {
    pageType: 'page_rank_products',
    isCssInVue: true,
    keepAlive: true,
    monitorTag: 'page_rank_products',
    ignoreScrollBehavior: true,
    notNeedCommonHeader: true,
  },
  component: () => import(/* webpackChunkName: "rank-products-page" */ '../../rank_products/Container.vue')
})


/**
 * 热销榜单新规则页
 * @param {*} langPath 
 * @returns 
 */
export const createRankProductsRulesRoute = langPath => ({
  path: `${langPath}/ranking_list/rules`,
  name: 'page_rank_products_rules',
  meta: {
    pageType: 'page_rank_products_rules',
    isCssInVue: true,
    keepAlive: true,
    notNeedCommonHeader: true,
    scrollTop: true,
    monitorTag: 'page_rank_products_rules',
  },
  component: () => import(/* webpackChunkName: "rank-products-rules" */ '../../rank_products/RulesContainer.vue')
})
